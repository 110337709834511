import { gql } from '@apollo/client';

import { useUpdateProductPriceMutation, useUpdateLiningPriceMutation, UpdateRetailPriceInput } from '@graphql';
import { Input, RightModal } from 'components';
import { Button } from 'modules/common';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { PriceArg } from 'modules/pricing/pricing.types';
import { formatAsCurrency } from 'helpers/reporting-helpers';
import { useActiveSession } from 'hooks/useActiveSessionContext';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  price: PriceArg;
}

export const PricingModal = ({ isOpen, setIsOpen, price }: Props): React.ReactElement => {
  const { tailor } = useActiveSession();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateRetailPriceInput>();

  const onClose = () => {
    setIsOpen(false);
    reset();
  };

  const onCompleted = () => {
    toast.success(`Price updated.`);
    onClose();
  };

  const onError = () => toast.error('Something went wrong, please try again.');

  const [updateProductPrice, { loading: isLoadingProduct }] = useUpdateProductPriceMutation({
    onCompleted,
    onError,
  });

  const [updateLiningPrice, { loading: isLoadingLining }] = useUpdateLiningPriceMutation({
    onCompleted,
    onError,
  });

  const onSubmit = handleSubmit(async (formData) => {
    const payload = {
      variables: {
        updateRetailPriceInput: {
          id: price.id,
          amount: formData.amount,
        },
      },
    };

    if (price.type === 'Fabric') {
      updateProductPrice(payload);
    } else if (price.type === 'Lining') {
      updateLiningPrice(payload);
    }
  });

  return (
    <RightModal isOpen={isOpen} setIsOpen={setIsOpen} title={price ? `Update ${price?.type} Price` : `Create ${price?.type} Price`} onClose={onClose}>
      <ul className="text-sm mb-4">
        <li>
          Category: <span className="font-semibold">{price?.productCategorySetting.truncatedName}</span>
        </li>
        <li>
          Tier: <span className="font-semibold">{price?.tier.name}</span>
        </li>
        <li>
          Current Price:{' '}
          <span className="font-semibold">{price?.amount ? formatAsCurrency(price?.amount, tailor.locale, tailor.currency) : 'NO PRICE SET'}</span>
        </li>
      </ul>
      <form onSubmit={onSubmit} className="sm:grid sm:grid-cols-2 gap-4">
        <div>
          <Input
            label="New Price"
            register={register('amount', { required: 'Please enter an amount.' })}
            htmlProps={{ type: 'text' }}
            errorMessage={errors?.amount?.message.toString()}
          />
        </div>

        <div className="pt-4 ml-auto">
          <Button type="submit" isDisabled={isLoadingProduct || isLoadingLining}>
            Update
          </Button>
        </div>
      </form>
    </RightModal>
  );
};

PricingModal.mutations = {
  updateProductPrice: gql`
    mutation UpdateProductPrice($updateRetailPriceInput: UpdateRetailPriceInput!) {
      updateProductRetailPrice(updateRetailPriceInput: $updateRetailPriceInput) {
        id
        amount
        productCategory
        productCategorySetting {
          key
          truncatedName
        }
        tier: fabricTier {
          id
          name
        }
      }
    }
  `,
  updateLiningPrice: gql`
    mutation UpdateLiningPrice($updateRetailPriceInput: UpdateRetailPriceInput!) {
      updateLiningRetailPrice(updateRetailPriceInput: $updateRetailPriceInput) {
        id
        amount
        productCategory
        productCategorySetting {
          key
          truncatedName
        }
        tier: liningTier {
          id
          name
        }
      }
    }
  `,
};
