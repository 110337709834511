import { gql } from '@apollo/client';
import { isEqual } from 'lodash';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import {
  DesignOptionsFormProviderGroupsFragment,
  useDesignOptionsFormProviderConfigurationQuery,
  useDesignOptionsFormProviderOrderItemQuery,
} from '@graphql';
import { parseRouteId } from 'modules/common/common.helpers';
import mixpanelService from 'services/mixpanel.service';
import { ConfigGroup } from 'types/common';
import { deserializeDesignOptions, getHiddenFields, getHiddenOptionsToReset, getIsFieldHidden, parseDesignOptions } from './designOptions.helpers';
import { DesignOptionKeyValue, HiddenOptionConfigType, OrderItemWithConflict } from './designOptions.types';
import { OrderItemFabricTabs } from 'modules/orders/components/OrderItemFabricTabs';
import { OrderItemProgressSideBar } from 'modules/orderItem/components/OrderItemProgressSideBar';
import { DesignOptionsContext, DesignOptionsContextType } from './designOptions.context';
import { DesignOptions } from './designOptionsConflict.gql';

type Props = {
  children: ReactNode;
  onSave?: (options: DesignOptionKeyValue[], orderItem: OrderItemWithConflict) => void;
  ignoreFields?: string[];
  selectedDesignId?: string;
  isTemplate: boolean;
};

export const DesignOptionsFormProvider = ({ children, onSave, ignoreFields, selectedDesignId, isTemplate }: Props) => {
  const {
    query: { itemId },
  } = useRouter();
  const [selectedGroup, setSelectedGroup] = useState<ConfigGroup>(null);
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [designOptionGroups, setDesignOptionGroups] = useState<DesignOptionsFormProviderGroupsFragment[]>([]);
  const [hiddenFields, setHiddenFields] = useState<HiddenOptionConfigType[]>([]);
  const [isDirty, setIsDirty] = useState(false);

  const { data: orderItemData, loading } = useDesignOptionsFormProviderOrderItemQuery({
    variables: { orderItemId: parseRouteId(itemId), isTemplate },
    skip: !itemId,
  });

  const designId = useMemo(() => {
    if (selectedDesignId) return selectedDesignId;
    if (orderItemData?.orderItem.designs.length === 1) return orderItemData?.orderItem.designs[0].id;
    if (orderItemData?.orderItem.productCategorySetting.requiresLining) {
      return orderItemData.orderItem.designs.find((design) => design.garmentCategory.requiresLining).id;
    }
    return orderItemData?.orderItem.designs[0].id;
  }, [orderItemData]);

  const { data: config, loading: configurationIsLoading } = useDesignOptionsFormProviderConfigurationQuery({
    skip: !designId || !orderItemData?.orderItem.designs?.find((design) => design.id === designId)?.garmentCategory?.key,
    variables: { garmentCategory: orderItemData?.orderItem.designs?.find((design) => design.id === designId)?.garmentCategory?.key },
    onCompleted(data) {
      const foundationGroups = data.designConfig.groups.filter((group) => !group.extra && !group.hidden);
      setSelectedGroup(foundationGroups.length ? foundationGroups[0] : data.designConfig.groups[0]);

      const currentDesignOptionGroups = parseDesignOptions(data.designConfig.groups, ignoreFields, orderItemData.orderItem.liningOptions);
      const hiddenFields = getHiddenFields(currentDesignOptionGroups);

      setDesignOptionGroups(currentDesignOptionGroups);
      setHiddenFields(hiddenFields);

      // sync form data
      const { orderItem } = orderItemData;
      const design = orderItem.designs.find((design) => design.id === designId);
      setFormData(deserializeDesignOptions(currentDesignOptionGroups, design.options));
    },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    // update mutation can change form data, so we listen to order item changes
    if (orderItemData && designOptionGroups.length > 0) {
      const { orderItem } = orderItemData;
      const design = orderItem.designs.find((design) => design.id === designId);

      setFormData((prevFormData) => {
        const newFormData = deserializeDesignOptions(designOptionGroups, design.options);
        return isEqual(prevFormData, newFormData) ? prevFormData : newFormData;
      });
    }
    setIsDirty(false);
  }, [orderItemData, designOptionGroups]);

  const setField = ({ id: typeCode, value }: { id: string; value: string; delaySave?: boolean }) => {
    setFormData({ ...formData, [typeCode]: value });
  };

  const setAndSaveField = ({
    id: typeCode,
    value,
    displayValue,
    label,
    forceSave = false,
  }: {
    id: string;
    value: string;
    displayValue: string | number;
    label: string;
    forceSave?: boolean;
  }) => {
    const newFormData = { ...formData, [typeCode]: value };

    if (isEqual(newFormData, formData) && !forceSave) return;

    const options = getHiddenOptionsToReset(designOptionGroups, newFormData, hiddenFields, typeCode);

    setIsDirty(true);

    onSave([{ typeCode, value }, ...options], orderItemData.orderItem);

    mixpanelService.track('ORDER_ITEM_DESIGN_OPTION_SAVE', {
      orderItemId: orderItemData.orderItem.id,
      typeCode,
      label,
      value,
      displayValue,
    });

    setFormData(newFormData);
  };

  const getPrice = ({ typeCode }: { typeCode: string }) => {
    return orderItemData.orderItem.designs.find((design) => design.id === designId).options.find((option) => option.typeCode === typeCode)?.price;
  };

  const value: DesignOptionsContextType = useMemo(
    () => ({
      formData,
      designOptionGroups,
      setField,
      setAndSaveField,
      getIsFieldHidden: (field) => getIsFieldHidden(field, formData, hiddenFields),
      conflicts: orderItemData?.orderItem.designs?.find((design) => design.id === designId)?.conflicts,
      config: {
        foundations: config?.designConfig.groups.filter((group) => !group.extra && !group.hidden) || [],
        extras: config?.designConfig.groups.filter((group) => group.extra && !group.hidden) || [],
      },
      selectedGroup,
      setSelectedGroup,
      getPrice,
      loading: loading || configurationIsLoading,
      isTemplate: orderItemData?.orderItem.isTemplate,
      isDirty,
    }),
    [formData, orderItemData, selectedGroup, config, loading, isDirty]
  );

  return <DesignOptionsContext.Provider value={value}>{children}</DesignOptionsContext.Provider>;
};

DesignOptionsFormProvider.fragments = {
  orderItem: gql`
    ${DesignOptions.fragments.conflicts}
    fragment DesignOptionsFormProviderOrderItemFragment on OrderItem {
      id
      productCategory
      productCategorySetting {
        key
        requiresLining
      }
      designs {
        id
        garmentCategory {
          key
          requiresLining
        }
        options {
          id
          value
          typeCode
          price
        }
        conflicts {
          ...DesignOptionsFormProviderConflictsFragment
        }
      }
      liningOptions {
        enableCmtLining
        enableCustomLining
        cmtDefaultOptions {
          key
          value
        }
        cmtOptions {
          key
          value
        }
      }
      lining {
        id
        code
      }
      fabric {
        id
        code
      }
      customFabric
      isTemplate
      estimatedDeliveryDate {
        formatted(format: "ll")
      }
      retail {
        total
        discountedPrice
        discountedAmount
      }
    }
  `,

  type: gql`
    fragment DesignOptionsFormProviderDesignTypeOffering on DesignTypeOffering {
      id
      code
      obsolete
      groupCode
      groupName
      requiredStockCheck
      options {
        id
        name
        code
        isDefault
        obsolete
        cacheIllustration {
          url
        }
        image {
          url
        }

        priceFormatted {
          amount
          formatted
        }
        sortOrder
      }

      inputType
      obsolete
      showOnSelection
      hiddenOption
      hiddenValues
      placeholder
      defaultValue
      maxlength
    }
  `,
  types: gql`
    fragment DesignOptionsFormProviderSubGroupTypes on DesignConfigSubGroupDesignType {
      name
      code
      designTypeOffering {
        ...DesignOptionsFormProviderDesignTypeOffering
      }
    }
  `,
  subGroups: gql`
    fragment DesignOptionsFormProviderSubGroups on DesignConfigSubGroup {
      id
      name
      extra
      hidden
      types {
        ...DesignOptionsFormProviderSubGroupTypes
      }
    }
  `,
  groups: gql`
    fragment DesignOptionsFormProviderGroups on DesignConfigGroup {
      id
      name
      extra
      hidden
      subGroups {
        ...DesignOptionsFormProviderSubGroups
      }
    }
  `,
  config: gql`
    fragment DesignOptionsFormProviderConfig on DesignConfig {
      id
      groups {
        ...DesignOptionsFormProviderGroups
      }
    }
  `,
};

DesignOptionsFormProvider.queries = {
  garmentDesignOptions: gql`
    ${DesignOptionsFormProvider.fragments.orderItem}
    ${OrderItemFabricTabs.fragments.fabricAndLining}
    ${OrderItemProgressSideBar.fragments.orderItem}

    query DesignOptionsFormProviderOrderItem($orderItemId: ID!, $isTemplate: Boolean!) {
      orderItem(orderItemId: $orderItemId, isTemplate: $isTemplate) {
        ...DesignOptionsFormProviderOrderItemFragment
        ...OrderItemFabricTabsLayout
        ...OrderItemProgressSideBarFragment
      }
    }
  `,
  config: gql`
    ${DesignOptionsFormProvider.fragments.config}

    query DesignOptionsFormProviderConfiguration($garmentCategory: GarmentCategory!) {
      designConfig(garmentCategory: $garmentCategory) {
        ...DesignOptionsFormProviderConfig
      }
    }
  `,
};
