import React, { memo, ReactNode, useState } from 'react';

import { HeaderLink, HeaderLinkSelect } from 'components';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { getDefaultQueryParams as getOrderParams } from 'pages/orders';
import { getDefaultQueryParams as getAlterationParams } from 'pages/alterations';
import { useFeatureAsync } from 'hooks';

import cn from 'classnames';
import Link from 'next/link';
import TaperLogoSvg from 'assets/svg/taper-logo.svg';
import { NewDesignFlowSwitch } from './NewDesignFlowSwitch';
import { gql } from '@apollo/client';
import { useUpdateUserDesignFlowPreferenceMutation } from '@graphql';
import { toast } from 'react-toastify';
import { TryItNowLink } from './TryItNowLink';
import { GiveFeedback } from './GiveFeedback';
import { ExternalLink } from './ExternalLink';
import amplitudeService from 'services/amplitude.service';

type Props = {
  children: ReactNode;
  className?: string;
  mobileContent?: ReactNode;
  logoUrl?: string;
  isLoading?: boolean;
};

const BASE_CLASSES = [
  'fixed',
  'top-0',
  'left-0',
  'w-full',
  'shadow-md',
  'z-40',
  'bg-gray-900',
  'text-white',
  'flex',
  'justify-center',
  'print:hidden',
];

const HeaderContainer = memo(({ className, children, mobileContent, logoUrl, isLoading }: Props) => {
  const classes = cn(className, BASE_CLASSES);

  return (
    <header className={classes}>
      {/* TODO: Need to support mobile nav properly */}
      {!isLoading && (
        <nav className="flex items-center justify-between h-14 sm:px-6 md:px-8 md:h-16 w-full max-w-7xl">
          <div className="flex items-center w-full">
            <Link href="/dashboard" className="flex items-center font-bold mr-6">
              {logoUrl ? <img src={logoUrl} className="max-w-xs h-7 object-contain" alt="brand logo" /> : <TaperLogoSvg />}
            </Link>
            {!mobileContent && children}
            {mobileContent && <span className="hidden lg:flex items-center w-full mr-16">{children}</span>}
          </div>
          <div className="flex items-center">
            {mobileContent && <span className="block lg:hidden mr-4">{mobileContent}</span>}
            <Link href="/logout" className="w-max text-white text-sm font-medium">
              Log out
            </Link>
          </div>
        </nav>
      )}
    </header>
  );
});

export const TheHeader = () => {
  const { loading, user, isAdmin, isSuperAdmin, tailor, defaultShowroom } = useActiveSession();
  const shouldAllowNewDesignFlow = tailor?.offeringConfig?.shouldAllowNewDesignFlow;
  const showDesignFlowV2Toggle = user.showDesignFlowV2Toggle;

  const ordersParams = getOrderParams({ showroom: defaultShowroom?.id });
  const alterationParams = getAlterationParams();
  const { enabled: userManagementFF } = useFeatureAsync('USER_MANAGEMENT');
  const { enabled: discountsFF } = useFeatureAsync('DISCOUNTS');

  const features = tailor?.config?.availableFeatures || [];
  const hasDiscountConfig = features.some((item) => item.name.toLowerCase() === 'discounts' && item.enabled);
  const discountEnabledEmail = features.some((item) => item.name.toLowerCase() === 'discounts' && item.allowedEmail.includes(user.email));

  const enforcedDesignFlowSelection = user.enforcedDesignFlowSelection;
  const [currentDesignFlow, setCurrentDesignFlow] = useState({ label: enforcedDesignFlowSelection, value: enforcedDesignFlowSelection });
  const [updateDesignFlowPreference] = useUpdateUserDesignFlowPreferenceMutation({
    refetchQueries: ['UseActiveSession'],
    onCompleted: (res) => {
      toast.success(`Design flow updated to ${res?.updateUserDesignFlowPreference?.enforcedDesignFlowSelection}`);
    },

    onError: () => {
      toast.error('Please try again.');
    },
  });
  const onStatusChange = async ({ val, label }: { val: string; label: string }) => {
    await updateDesignFlowPreference({
      variables: {
        updateDesignFlowPreferenceInput: {
          enforcedDesignFlow: val,
          email: user.email,
        },
      },
    });
    amplitudeService.track('Design 2.0 Switched', { status: val === 'V2' });
    setCurrentDesignFlow({ value: val, label });
  };

  return (
    <HeaderContainer
      logoUrl={tailor?.logoUrl}
      isLoading={loading}
      mobileContent={
        <HeaderLinkSelect
          name="Menu"
          align="right"
          items={[
            {
              name: 'Dashboard',
              path: `/dashboard`,
            },
            {
              name: 'Orders',
              path: `/orders`,
              query: ordersParams,
            },
            {
              name: 'Customers',
              path: `/customers`,
            },
            {
              name: 'Alterations',
              path: `/alterations`,
              query: alterationParams,
            },
            {
              name: 'Fabric',
              path: `/fabrics`,
            },
            {
              name: 'Alteration Providers',
              path: `/alteration-providers`,
            },
            {
              name: 'Alteration Options',
              path: `/alteration-options`,
            },
            {
              //component: !isAdmin && !isSuperAdmin && <Typeform type="MOBILE" />,
            },
          ]}
        />
      }
    >
      <HeaderLink href="/dashboard">Dashboard</HeaderLink>
      <HeaderLink href="/orders" query={ordersParams}>
        Orders
      </HeaderLink>
      <HeaderLink href="/customers">Customers</HeaderLink>

      {isAdmin || isSuperAdmin ? (
        <HeaderLinkSelect
          name="Alterations"
          items={[
            {
              name: 'Alterations',
              path: `/alterations`,
              query: alterationParams,
            },
            {
              name: 'Alteration Providers',
              path: `/alteration-providers`,
            },
            {
              name: 'Alteration Options',
              path: `/alteration-options`,
            },
          ]}
        />
      ) : (
        <HeaderLink href="/alterations" query={alterationParams}>
          Alterations
        </HeaderLink>
      )}
      <HeaderLink href="/fabrics">Fabric</HeaderLink>
      {(isAdmin || isSuperAdmin) && <HeaderLink href="/reporting">Reporting</HeaderLink>}
      {/* {!isAdmin && !isSuperAdmin && <Typeform />} */}
      {isAdmin && (
        <HeaderLinkSelect
          name="Admin"
          items={[
            {
              name: 'Design Options',
              path: `/design-options/garment-categories`,
            },
            {
              name: 'Design Templates',
              path: `/design-templates`,
            },
            {
              name: 'Invoices',
              path: `/invoices`,
            },
            {
              name: 'Pricing',
              path: `/pricing`,
            },
            userManagementFF && {
              name: 'User Management',
              path: `/user-management`,
            },
            (discountEnabledEmail || (discountsFF && hasDiscountConfig)) && {
              name: 'Discounts',
              path: `/discount`,
            },
          ]}
        />
      )}
      {isSuperAdmin && (
        <HeaderLinkSelect
          name="Admin"
          items={[
            {
              name: 'Design Options',
              path: `/design-options/garment-categories`,
            },
            {
              name: 'Design Options Config',
              path: `/design-options-config`,
            },
            {
              name: 'Design Templates',
              path: `/design-templates`,
            },
            {
              name: 'Invoices',
              path: `/invoices`,
            },
            {
              name: 'Pricing',
              path: `/pricing`,
            },
            userManagementFF && {
              name: 'User Management',
              path: `/user-management`,
            },
            (discountEnabledEmail || (discountsFF && hasDiscountConfig)) && {
              name: 'Discounts',
              path: `/discount`,
            },
          ]}
        />
      )}
      {shouldAllowNewDesignFlow && showDesignFlowV2Toggle && (
        <HeaderLinkSelect
          name="Design 2.0"
          items={[
            {
              component: <TryItNowLink />,
            },
            {
              component: <ExternalLink label="Learn more" url="https://www.taper.ai/beta-design-2-0" />,
            },
            {
              component: <ExternalLink label="FAQ" url="https://intercom.help/taper_knowledgebase/en/collections/12006008-design-2-0" />,
            },

            {
              component: <GiveFeedback />,
            },
            {
              component: (
                <NewDesignFlowSwitch
                  id={'newDesignFloq'}
                  label={user.designFlowToggleName}
                  checkedOption={currentDesignFlow}
                  options={[
                    { value: 'V1', label: 'V1' },
                    { value: 'V2', label: 'V2' },
                  ]}
                  onChange={function ({ value, label }: { value: string; label: string }): void {
                    onStatusChange({ val: value, label });
                  }}
                />
              ),
            },
          ]}
        />
      )}
    </HeaderContainer>
  );
};
TheHeader.mutations = {
  updateDesignFlowPreference: gql`
    mutation UpdateUserDesignFlowPreference($updateDesignFlowPreferenceInput: UpdateDesignFlowPreference!) {
      updateUserDesignFlowPreference(updateDesignFlowPreferenceInput: $updateDesignFlowPreferenceInput) {
        email
        enforcedDesignFlowSelection
      }
    }
  `,
};
