export const IMG_PREFIX = 'instichu_jacket1';

export const DEFAULT_DESIGN_OPTIONS = {
  FABRIC: 'TTV2120',
  LINING: 'TR271',
  T010206: 'TU25', // smoking color
  T010207: 'T11', // button
  T010202: 'T01020201', // closure
  T010203: 'T01020314', // lapel
};

export const MAPPED_OPTIONS = {
  hasFormalLapel: {
    typeCode: 'T010205',
    yes: 'T01020502',
    no: 'Fabric',
  },
  smokingColor: {
    typeCode: 'T010206', // Smoking Color (Formal Lapel)
    imageIdPerCode: {
      TU25: { id: 'Black Formal', original: true },
      TU30: { id: 'Navy Formal', original: true },
      TU42: { id: 'Navy Formal', original: false },
      TX45: { id: 'Navy Formal', original: false },
      TU24: { id: 'White Formal', original: true },
      TX19: { id: 'White Formal', original: false },
      TU40: { id: 'White Formal', original: false },
    },
  },
  liningPlacement: {
    typeCode: 'T010224',
    fullyLined: 'T01022401',
    unlined: [
      'T01022402', // Unlined with center wedge
      'T01022405', // Unlined with Q wedge
      'T01022406', // Completely unlined
    ],
  },
  button: {
    typeCode: 'T010207', // Button
    fabricButton: 'Self Fabric Buttons',
    codes: [
      'Self Fabric Buttons',
      'T10',
      'T100',
      'T101',
      'T102',
      'T103',
      'T104',
      'T105',
      'T106',
      'T107',
      'T108',
      'T109',
      'T11',
      'T110',
      'T111',
      'T112',
      'T113',
      'T114',
      'T115',
      'T116',
      'T117',
      'T118',
      'T119',
      'T12',
      'T120',
      'T121',
      'T122',
      'T123',
      'T124',
      'T125',
      'T126',
      'T127',
      'T128',
      'T129',
      'T130',
      'T131',
      'T132',
      'T133',
      'T134',
      'T135',
      'T136',
      'T137',
      'T138',
      'T139',
      'T140',
      'T141',
      'T142',
      'T143',
      'T20',
      'T21',
      'T23',
      'T26',
      //'T27', it is using T26, pending to fix
      'T28',
      'T29',
      'T3',
      'T30',
      'T31',
      'T4',
      'T40',
      'T41',
      'T42',
      'T44',
      'T46',
      'T47',
      'T48',
      'T50',
      'T51',
      'T52',
      'T53',
      'T54',
      'T55',
      'T56',
      'T59',
      'T6',
      'T60',
      'T61',
      'T62',
      'T63',
      'T64',
      'T65',
      'T67',
      'T69',
      'T7',
      'T70',
      'T71',
      'T72',
      'T73',
      'T74',
      'T75',
      'T76',
      'T77',
      'T78',
      'T79',
      'T80',
      'T81',
      'T82',
      'T83',
      'T85',
      'T86',
      'T87',
      'T88',
      'T89',
      'T9',
      'T90',
      'T91',
      'T92',
      'T93',
      'T95',
      'T96',
      'T97',
      'T98',
      'T99',
      'TG05',
      'TG06',
      'TOC01',
      'TOC02',
      'TOC03',
      'TOC04',
      'TOC05',
      'TOC06',
      'TOC07',
      'TOC08',
      'TOC09',
      'TOC10',
      'TOC11',
      'TOC12',
      'TOC13',
      'TOC14',
      'TOC15',
      'TOC16',
      'TSN54',
      'TUB24',
      'TUB25',
      'TUB26',
      'TUB27',
      'TUB28',
      'TUB29',
      'TUB30',
      'TUB31',
      'TUB32',
      'TUB33',
      'TUB34',
      'TUB35',
      'TUB36',
      'TUB38',
      'TUB39',
      'TUB40',
      'TXB13',
      'TXB14',
      'TXB15',
      'TXB16',
      'TXB19',
      'TXB20',
      'TXB21',
    ],
    buttonsWithoutHoles: [
      'Self Fabric Buttons',
      'T44',
      'T46',
      'T47',
      'T48',
      'T50',
      'T51',
      'T69',
      'T70',
      'T93',
      'T95',
      'T96',
      'T97',
      'T98',
      'T99',
      'T101',
      'T102',
      'T103',
      'T104',
      'T105',
      'T106',
      'T108',
      'T109',
      'T110',
      'T111',
      'T112',
      'T113',
      'T114',
      'TG05',
      'TG06',
      'TG09',
      'TSN54',
      'TUB24',
      'TUB25',
      'TUB26',
      'TUB27',
      'TUB28',
      'TUB29',
      'TUB30',
      'TUB31',
      'TUB32',
      'TUB33',
      'TUB34',
      'TUB35',
      'TUB36',
      'TUB37',
      'TUB38',
      'TUB39',
      'TUB40',
      'TXB13',
      'TXB14',
      'TXB15',
      'TXB16',
      'TXB19',
      'TXB20',
      'TXB21',
    ],
  },
  closure: {
    typeCode: 'T010202', // Closure
    imageIdPerCode: {
      T01020201: '1', // 2 button
      T01020202: '2', // 3 button
      T01020204: '3', // 1 button
      T01020203: '4', // 3 button rollover
      T01020205: '5', // 6 button DB
      T01020206: '6', // 4 button DB
      T01020207: '7', // 2 button DB
      T01020208: '8', // 5B Mandarin Collar
      T01020209: '9', // 6x1 Button DB
      T01020210: '10', // 4x1 Button DB
    },
  },
  lapel: {
    typeCode: 'T010203', // Lapel
    imageIdPerCode: {
      T01020310: { id: '3', original: true }, // Notch with low gorge 8.2cm/3.2in (original)
      T01020330: { id: '3', original: false }, // Notch with low gorge 9.2cm/3.6in
      T01020331: { id: '3', original: false }, // Notch with low gorge 10.2cm/4in
      T01020332: { id: '3', original: false }, // Notch with low gorge 11.2cm/4.4in

      T01020311: { id: '4', original: true }, // Notch lapel 6cm/2.4in (original)
      'BB-T01020343': { id: '4', original: false }, // Notch lapel 6.5cm
      T01020312: { id: '4', original: false }, // Notch lapel 7cm/2.8in
      'BB-T01020339': { id: '4', original: false }, // Notch lapel 7.5cm
      T01020313: { id: '5', original: false }, // Notch lapel 8cm/3.1in
      'BB-T01020340': { id: '5', original: false }, // Notch lapel 8.5cm
      T01020314: { id: '5', original: true }, // Notch lapel 9cm/3.5in (original)
      'BB-T01020341': { id: '5', original: false }, // Notch lapel 9.5cm
      T01020315: { id: '5', original: false }, // Notch lapel 10cm/3.9in
      T01020316: { id: '6', original: true }, // Notch lapel 11cm/4.3in (original)
      T01020347: { id: '6', original: false }, // Notch lapel 12cm/4.7in
      T01020343: { id: '6', original: false }, // Notch Lapel 13cm/5.1in

      T01020338: { id: '1', original: false }, // Open peak lapel 7.0cm/2.75in
      T01020339: { id: '7', original: false }, // Open peak lapel 8.0cm/3.2in
      T01020340: { id: '7', original: false }, // Open peak lapel 9.0cm/3.5in
      T01020341: { id: '7', original: false }, // Open peak lapel 10.0cm/4in
      T01020342: { id: '8', original: false }, // Open peak lapel 11.0cm/4.3in

      T01020346: { id: '1', original: false }, // Peak lapel 6cm/2.4in
      T01020306: { id: '1', original: true }, // Peak lapel 7cm/2.8in
      T01020317: { id: '7', original: false }, // Peak lapel 8cm/3.1in
      T01020318: { id: '7', original: true }, // Peak lapel 9cm/3.5in (original)
      'BB-T01020344': { id: '7', original: false }, // Peak lapel 9.5cm
      T01020319: { id: '7', original: false }, // Peak lapel 10cm/3.9in
      T01020320: { id: '8', original: true }, // Peak lapel 11cm/4.3in (original)
      T01020348: { id: '8', original: false }, // Peak lapel 12cm/4.7in
      T01020344: { id: '8', original: false }, // Peak lapel 13cm / 5.1in

      T01020307: { id: '2', original: true }, // Shawl collar 5cm/2in (original)
      T01020308: { id: '2', original: false }, // Shawl collar 6cm/2.4in
      'BB-T01020342': { id: '9', original: false }, // Shawl Collar 6.5cm
      'BB-T01020338': { id: '9', original: false }, // Shawl Collar 7.0cm
      T01020321: { id: '9', original: true }, // Shawl collar 7.6cm/3in (original)

      T01020325: { id: '10', original: true }, // 1 Button Bib Shawl

      T01020327: { id: '11', original: false }, // Curved wide peak 8.5cm/3.4in
      T01020328: { id: '11', original: true }, // Curved wide peak 9.5cm/3.75in (original)
      T01020329: { id: '13', original: false }, // Curved wide peak 10.5cm/4.1in
      T01020326: { id: '13', original: true }, // Curved wide peak 11.5cm/4.5in (original)

      T01020333: { id: '', original: true }, // Stand collar
    },
  },
  chestPocket: {
    typeCode: 'T010210', // Chest Pocket
    withButton: 'T01021009',
    imageIdPerCode: {
      T01021001: { id: '1', original: true }, // 2.8 wide welted
      T01021002: { id: '1', original: false }, // 2.1 narrow welted
      T01021003: { id: '2', original: true }, // 2.8 round welted
      T01021004: { id: '3', original: true }, // Patch
      T01021005: { id: '4', original: true }, // Jetted
      T01021012: { id: '4', original: true }, // Jetted with zipper
      T01021006: { id: '5', original: true }, // Patch round
      T01021007: { id: '6', original: true }, // Patch with flap and pleat
      T01021009: { id: '7', original: true }, // Bellowed patch with pointed flap and button
      T01021010: { id: '8', original: true }, // Patch and pleat
      T01021011: { id: '9', original: true }, // N/A
    },
  },
  sidePocket: {
    typeCode: 'T010211', // Side Pockets
    withButton: 'T01021109',
    imageIdPerCode: {
      T01021101: '1', // Flap straight
      T01021102: '2', // Flap slanted
      T01021103: '3', // Patch /*ticketpocketflatsize*/,
      T01021104: '4', // Patch with flap
      T01021105: '5', // Patch rounded
      T01021106: '6', // Patch with flap and pleat
      T01021107: '7', // Jet straight
      T01021108: '8', // Jet slanted
      T01021109: '9', // Bellowed patch with pointed flap and button
      T01021110: '10', // Patch and pleat
    },
  },
  ticketPocket: {
    typeCode: 'T010213', // Ticket Pocket
    no: 'T01021301',
    flapStraight: 'T01021304',
    imageIdPerCode: {
      T01021302: '1', // Jet straight
      T01021303: '2', // Jet slanted
      T01021304: '3', // Flap straight
      T01021305: '4', // Flap slanted
      T01021306: '5', // Patch rounded
      T01021307: '6', // Patch
    },
  },
  sidePocketFlapSize: {
    typeCode: 'T010212', // Side Pocket Flap Size
    imageIdPerCode: {
      T01021201: '1', // Regular 5cm/2in
      T01021202: '2', // Narrow 4cm/1.6in
      T01021203: '3', // Wide 7cm/2.8in
      T01021405: '3', // Wide 5cm/2in
      T01021204: '4', // NA ?
    },
  },
};

export const TYPE_OPTIONS_WITH_PREVIEW = {
  [MAPPED_OPTIONS.hasFormalLapel.typeCode]: [MAPPED_OPTIONS.hasFormalLapel.no, MAPPED_OPTIONS.hasFormalLapel.yes],
  [MAPPED_OPTIONS.smokingColor.typeCode]: Object.keys(MAPPED_OPTIONS.smokingColor.imageIdPerCode).filter(
    (key) => MAPPED_OPTIONS.smokingColor.imageIdPerCode[key].original
  ),
  [MAPPED_OPTIONS.liningPlacement.typeCode]: [MAPPED_OPTIONS.liningPlacement.fullyLined, MAPPED_OPTIONS.liningPlacement.unlined[2]],
  [MAPPED_OPTIONS.button.typeCode]: MAPPED_OPTIONS.button.codes,
  [MAPPED_OPTIONS.closure.typeCode]: Object.keys(MAPPED_OPTIONS.closure.imageIdPerCode),
  [MAPPED_OPTIONS.lapel.typeCode]: Object.keys(MAPPED_OPTIONS.lapel.imageIdPerCode).filter(
    (key) => MAPPED_OPTIONS.lapel.imageIdPerCode[key].original
  ),
  [MAPPED_OPTIONS.chestPocket.typeCode]: Object.keys(MAPPED_OPTIONS.chestPocket.imageIdPerCode).filter(
    (key) => MAPPED_OPTIONS.chestPocket.imageIdPerCode[key].original
  ),
  [MAPPED_OPTIONS.sidePocket.typeCode]: Object.keys(MAPPED_OPTIONS.sidePocket.imageIdPerCode),
  [MAPPED_OPTIONS.ticketPocket.typeCode]: Object.keys(MAPPED_OPTIONS.ticketPocket.imageIdPerCode),
  [MAPPED_OPTIONS.sidePocketFlapSize.typeCode]: Object.keys(MAPPED_OPTIONS.sidePocketFlapSize.imageIdPerCode),
};
