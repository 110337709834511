import { ReactNode, useState } from 'react';
import { Button } from 'modules/common';
import { AddItemModal } from 'modules/orders/components/AddItemModal';
import amplitudeService from 'services/amplitude.service';

interface Props {
  isText?: boolean;
  isDisabled?: boolean;
  children?: ReactNode;
}

export const AddItemButton = ({ children, ...rest }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setIsModalOpen(true);
          amplitudeService.track('Order Item Add Clicked');
        }}
        {...rest}
      >
        {children ? children : 'Add items'}
      </Button>
      <AddItemModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};
