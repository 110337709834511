import { GarmentCategory } from '@graphql';
import { getJacketImages } from './jacket/get-images';
import { DesignOption, GetImagesResponse } from '../types';
import * as JacketPreview from './jacket/has-preview';
import * as PantsPreview from './pants/has-preview';
import { getPantsImages } from './pants/get-images';
import { DEFAULT_DESIGN_OPTIONS as JACKET_DEFAULT_DESIGN_OPTIONS } from './jacket/constants';
import { DEFAULT_DESIGN_OPTIONS as PANTS_DEFAULT_DESIGN_OPTIONS } from './pants/constants';

export const GET_IMAGES_PER_GARMENT_CATEGORY: Record<
  GarmentCategory | string,
  ((baseUrl: string, designOptions: DesignOption[], fabricCode?: string, liningCode?: string) => GetImagesResponse) | undefined
> = {
  [GarmentCategory.Jacket]: getJacketImages,
  [GarmentCategory.PremiumJacket]: getJacketImages,
  [GarmentCategory.Pants]: getPantsImages,
  [GarmentCategory.PremiumPants]: getPantsImages,
};

export const GET_PREVIEW_FN_PER_GARMENT_CATEGORY: Record<
  GarmentCategory | string,
  | {
      hasOptionPreview: (typeCode: string, optionCode?: string) => boolean;
      hasMaterialPreview: (materialCode: string, materialType: string) => boolean;
    }
  | undefined
> = {
  [GarmentCategory.Jacket]: JacketPreview,
  [GarmentCategory.PremiumJacket]: JacketPreview,
  [GarmentCategory.Pants]: PantsPreview,
  [GarmentCategory.PremiumPants]: PantsPreview,
};

export const GET_DEFAULT_PREVIEW_OPTIONS: Record<GarmentCategory | string, Record<string, string>> = {
  [GarmentCategory.Jacket]: JACKET_DEFAULT_DESIGN_OPTIONS,
  [GarmentCategory.PremiumJacket]: JACKET_DEFAULT_DESIGN_OPTIONS,
  [GarmentCategory.Pants]: PANTS_DEFAULT_DESIGN_OPTIONS,
  [GarmentCategory.PremiumPants]: PANTS_DEFAULT_DESIGN_OPTIONS,
};

export const isGarmentRenderSupported = (garmentCode: string) => GET_IMAGES_PER_GARMENT_CATEGORY[garmentCode] !== undefined;

export type ImageProperty = {
  size: { width: number; height: number };
  viewBox: { width: number; height: number };
  translate: { x: number; y: number };
};

export const IMAGE_PROPERTIES: Record<GarmentCategory | string, ImageProperty> = {
  [GarmentCategory.Jacket]: { size: { width: 1000, height: 1000 }, viewBox: { width: 540, height: 810 }, translate: { x: -500, y: -500 } },
  [GarmentCategory.PremiumJacket]: { size: { width: 1000, height: 1000 }, viewBox: { width: 540, height: 810 }, translate: { x: -500, y: -500 } },
  [GarmentCategory.Pants]: { size: { width: 1000, height: 1000 }, viewBox: { width: 540, height: 1000 }, translate: { x: -500, y: -500 } },
  [GarmentCategory.PremiumPants]: { size: { width: 1000, height: 1000 }, viewBox: { width: 540, height: 1000 }, translate: { x: -500, y: -500 } },
};
