import { ToastContainer as BaseToastContainer } from 'react-toastify';

const contextClass = {
  success: 'bg-emerald-500',
  error: 'bg-red-500',
  info: 'bg-blue-500',
  warning: 'bg-orange-500',
  default: 'bg-gray-800',
  dark: 'bg-white-600 font-gray-300',
};

export const ToastContainer = () => (
  <BaseToastContainer
    toastClassName={({ type }) =>
      contextClass[type || 'default'] + ' relative flex p-2 min-h-10 rounded justify-between overflow-hidden cursor-pointer print:hidden'
    }
    bodyClassName={() => 'text-sm font-white block p-2'}
    newestOnTop
    closeOnClick
    pauseOnHover
  />
);
