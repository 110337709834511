import { Icon, Input } from 'components';

type Props = {
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  onChange: (value: string) => void;
};

export const Search = ({ value, defaultValue, placeholder, onChange }: Props) => {
  return (
    <div className="outline-blue-500 border border-gray-200 rounded-md flex items-center px-2 w-full bg-white relative">
      <Icon icon="search" className="absolute text-gray-500 stroke-2" />
      <Input
        className="grow"
        htmlProps={{
          value,
          defaultValue,
          type: 'text',
          placeholder: placeholder ?? 'Search',
          className: 'border-0 w-full focus:ring-0 py-1.5 pl-6',
          onChange: ({ currentTarget }) => onChange(currentTarget.value),
        }}
      />
    </div>
  );
};
