import { DesignOptionsFormProviderOrderItemQuery, GarmentCategory } from '@graphql';
import { GarmentImagesModal } from './GarmentCanvas/GarmentImagesModal';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { useDesignOptionsForm } from 'modules/designOptions/designOptions.context';

type Props = {
  orderItem?: DesignOptionsFormProviderOrderItemQuery['orderItem'];
};

const supportedGarments = [GarmentCategory.Jacket];

export const FabricGarmentPreview = ({ orderItem }: Props) => {
  const { isDirty } = useDesignOptionsForm();
  const { shouldShowGarmentRenderer } = useActiveSession();

  return (
    <>
      {supportedGarments.map((garmentCategory) => {
        const design = orderItem.designs.find((d) => d.garmentCategory.key === garmentCategory);

        if (!design) {
          return null;
        }
        return (
          <GarmentImagesModal
            key={garmentCategory}
            designOptions={design.options.map(({ typeCode, value }) => ({ typeCode, code: value }))}
            garmentCategory={garmentCategory}
            isDirty={isDirty}
            hasConflicts={design.conflicts.length > 0}
            isOpen={shouldShowGarmentRenderer}
            fabricCode={orderItem.fabric?.code}
            liningCode={orderItem.lining?.code}
          />
        );
      })}
    </>
  );
};
