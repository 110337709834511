import { gql } from '@apollo/client';
import { OrderItemFabricTabsLayoutFragment, ProductCategory, useFabricListQuery, useUpdateOrderItemFabricMutation } from '@graphql';
import cn from 'classnames';
import { Icon } from 'components';
import { Input, RightModal } from 'components';
import { Button, Search, TaperLoading } from 'modules/common';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import mixpanelService from 'services/mixpanel.service';
import { FabricOrLiningCard } from 'modules/fabric/components/FabricOrLiningCard';

interface Props {
  onSelectFabric?: (fabric: OrderItemFabricTabsLayoutFragment['fabric']) => void;
  selectedFabric?: OrderItemFabricTabsLayoutFragment['fabric'];
  customFabric?: string;
  productCategory?: ProductCategory;
  isTemplate?: boolean;
}

export const FabricList = ({ onSelectFabric, selectedFabric, customFabric: orderItemCustomFabric, productCategory, isTemplate = false }: Props) => {
  const [searchText, setSearchText] = useState(selectedFabric?.code || '');
  const [customFabric, setCustomFabric] = useState(null);
  const { data, loading: isLoading } = useFabricListQuery({ variables: { code: searchText, productCategory }, fetchPolicy: 'cache-first' });
  const { fulfilment } = useActiveSession();
  const router = useRouter();
  const { itemId } = router.query;

  useEffect(() => {
    mixpanelService.track('FABRIC_SEARCH', { searchText });
  }, [searchText]);

  const [updateFabricMutation] = useUpdateOrderItemFabricMutation();

  const searchCoreFabrics = data?.searchCoreFabrics || [];
  const orderedFabrics = [...searchCoreFabrics].sort((a, b) => a.status.localeCompare(b.status));
  const showAddIcon = searchText && fulfilment === 'PDF';
  const addFabric = async () => {
    await updateFabricMutation({ variables: { orderItemId: String(itemId), orderItemInput: { customFabric }, isTemplate } });
    mixpanelService.track('ORDER_ITEM_CUSTOM_FABRIC_ADDED', { orderItemId: String(itemId), customFabric });
    setCustomFabric(null);
    setSearchText('');
  };

  const handleSelectFabric = (fabric: OrderItemFabricTabsLayoutFragment['fabric']) => {
    if (onSelectFabric != null) {
      mixpanelService.track('ORDER_ITEM_FABRIC_SELECTED', { ...fabric, orderItemId: String(itemId), searchText });
      onSelectFabric(fabric);
    }
  };

  const getInfoText = (searchText: string, orderItemCustomFabric: string) => {
    if (searchText) {
      return 'No fabric results';
    }

    return orderItemCustomFabric ? `Custom fabric '${orderItemCustomFabric}' selected` : 'Use search to find fabric';
  };

  return (
    <>
      <Search value={searchText} onChange={setSearchText} />
      {isLoading ? (
        <TaperLoading isCentred />
      ) : (
        <div className="mt-6 overflow-scroll-y">
          {!data?.searchCoreFabrics.length || !searchText ? (
            <div className="w-full h-96 flex flex-col items-center justify-center">
              {showAddIcon && <Icon icon="plus" className="m-2 rounded bg-amber-100 text-amber-400" />}
              <div>{getInfoText(searchText, orderItemCustomFabric)}</div>
              {showAddIcon && (
                <div className="flex flex-col items-center">
                  <span className="text-gray-500">Would you like to add a custom fabric?</span>
                  <Button className="mt-4" onClick={() => setCustomFabric(searchText)}>
                    Add Custom Fabric
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div className={cn('grid grid-cols-1 xl:grid-cols-2 gap-5')}>
              {orderedFabrics.map((fabric) => (
                <FabricOrLiningCard
                  key={fabric.id}
                  fabricOrLining={{ ...fabric, typename: 'fabric' }}
                  handleOnClick={() => handleSelectFabric(fabric)}
                  isSelected={selectedFabric?.id === fabric.id}
                />
              ))}
            </div>
          )}
        </div>
      )}
      <RightModal
        title="Add custom fabric"
        isOpen={!!customFabric}
        setIsOpen={setCustomFabric}
        submitButton={<Button onClick={addFabric}>Add</Button>}
      >
        <Input
          label="Name"
          htmlProps={{
            type: 'text',
            value: customFabric,
            onChange: (e) => setCustomFabric(e.currentTarget.value),
          }}
        />
      </RightModal>
    </>
  );
};

FabricList.fragments = {
  list: gql`
    fragment FabricList on CoreFabric {
      id
      name
      code
      status
      fabricCollection
      replenishmentDate
      vendor
      stock
      imageUrl
    }
  `,
};

FabricList.query = gql`
  ${FabricList.fragments.list}

  query FabricList($code: String!, $productCategory: ProductCategory) {
    searchCoreFabrics(code: $code, productCategory: $productCategory) {
      ...FabricList
    }
  }
`;
