import { DesignOption } from './types';
import { GarmentImages } from './GarmentImages';
import { GarmentCategory, useGetImageSettingQuery } from '@graphql';
import { useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ArrowsExpandIcon, XIcon } from '@heroicons/react/solid';
import cn from 'classnames';

type Props = {
  isOpen: boolean;
  designOptions: DesignOption[];
  garmentCategory: GarmentCategory;
  fabricCode?: string;
  liningCode?: string;
  hasConflicts: boolean;
  isDirty: boolean;
};

type SizeStatus = {
  isMinimized: boolean;
  maximizedMode: `${number}x`;
};

const POSSIBLE_SIZES = [1, 2, 2.5, 3];

export const GarmentImagesModal = ({ isOpen, isDirty, designOptions, garmentCategory, hasConflicts, fabricCode, liningCode }: Props) => {
  const divRef = useRef<HTMLDivElement>();
  const isMovingRef = useRef<boolean>();
  const initialMouseRef = useRef<[number, number]>(null);
  const [sizeStatus, setSizeStatus] = useState<SizeStatus>({ isMinimized: true, maximizedMode: '1x' });

  const { data } = useGetImageSettingQuery({ fetchPolicy: 'cache-first' });

  const cancelDrag = () => {
    initialMouseRef.current = null;
    isMovingRef.current = false;
  };

  useLayoutEffect(() => {
    if (isOpen) {
      const parentWidth = divRef.current.parentElement.clientWidth;

      divRef.current.style.left = `${parentWidth / 2 - 400 / 2}px`;
      divRef.current.style.top = '75px';
    }
  }, [isOpen]);

  const width = sizeStatus.isMinimized ? 192 : Number(sizeStatus.maximizedMode.replace('x', '')) * 384;

  return isOpen
    ? createPortal(
        <div
          ref={divRef}
          className="absolute top-0 z-50 bg-white rounded-lg border-2"
          onDragStart={(e) => e.preventDefault()}
          onMouseMove={(e) => {
            if (isMovingRef.current) {
              if (!initialMouseRef.current) {
                const clientRect = e.currentTarget.getBoundingClientRect();
                const deltaX = e.clientX - clientRect.left;
                const deltaY = e.clientY - clientRect.top;
                initialMouseRef.current = [deltaX, deltaY];
              }
              const parentRect = e.currentTarget.parentElement.getBoundingClientRect();
              const x = e.clientX - parentRect.left - initialMouseRef.current[0];
              const y = e.clientY - parentRect.top - initialMouseRef.current[1];
              divRef.current.style.top = `${y}px`;
              divRef.current.style.left = `${x}px`;
            }
          }}
          onMouseDown={() => {
            isMovingRef.current = true;
          }}
          onMouseUp={cancelDrag}
          onMouseLeave={cancelDrag}
        >
          <div className="cursor-move" style={{ width }}>
            <div className="flex justify-between p-2">
              <span>Garment Preview</span>
              <div
                className={cn('grid divide-x text-center items-center border rounded-md', {
                  'grid-cols-5 w-56': !sizeStatus.isMinimized,
                  'grid-cols-1 w-7': sizeStatus.isMinimized,
                })}
              >
                {!sizeStatus.isMinimized && (
                  <>
                    {POSSIBLE_SIZES.map((sizeValue) => (
                      <span
                        key={sizeValue.toString()}
                        onClick={() => setSizeStatus((prev) => ({ ...prev, maximizedMode: `${sizeValue}x` }))}
                        className={cn('cursor-pointer', { 'font-bold': sizeStatus.maximizedMode === `${sizeValue}x` })}
                      >
                        {sizeValue}x
                      </span>
                    ))}
                  </>
                )}
                <div>
                  {sizeStatus.isMinimized ? (
                    <ArrowsExpandIcon
                      className="cursor-pointer h-4 mx-auto"
                      onClick={() => setSizeStatus((prev) => ({ ...prev, isMinimized: false }))}
                    />
                  ) : (
                    <XIcon className="cursor-pointer h-4 mx-auto" onClick={() => setSizeStatus((prev) => ({ ...prev, isMinimized: true }))} />
                  )}
                </div>
              </div>
            </div>
            {!sizeStatus.isMinimized && (
              <GarmentImages
                baseUrl={data.settings.garmentRenderBaseImageUrl ?? ''}
                isDirty={isDirty}
                designOptions={designOptions}
                garmentCategory={garmentCategory}
                hasConflicts={hasConflicts}
                fabricCode={fabricCode}
                liningCode={liningCode}
              />
            )}
          </div>
        </div>,
        document.body
      )
    : null;
};
