import cn from 'classnames';
import React, { memo } from 'react';

import AddCircleSvg from 'assets/svg/icon-add-circle.svg';
import AddSvg from 'assets/svg/icon-add.svg';
import ArchiveSvg from 'assets/svg/icon-archive.svg';
import CardSvg from 'assets/svg/icon-card.svg';
import ChevronDownSvg from 'assets/svg/icon-chevron-down.svg';
import ChevronUpSvg from 'assets/svg/icon-chevron-up.svg';
import ClockSvg from 'assets/svg/icon-clock.svg';
import ClipboardOutlineSvg from 'assets/svg/icon-outline-clipboard.svg';
import CompletedSvg from 'assets/svg/icon-completed.svg';
import ConnectorSvg from 'assets/svg/icon-connector.svg';
import CrossSvg from 'assets/svg/icon-cross.svg';
import DangerFilledSvg from 'assets/svg/icon-danger-filled.svg';
import DangerStrokeSvg from 'assets/svg/icon-danger-stroke.svg';
import DuplicateSvg from 'assets/svg/icon-duplicate.svg';
import EditSvg from 'assets/svg/icon-edit.svg';
import FabricSvg from 'assets/svg/icon-fabric.svg';
import InfoFilledSvg from 'assets/svg/icon-info-filled.svg';
import InfoStrokeSvg from 'assets/svg/icon-info-stroke.svg';
import LockSvg from 'assets/svg/icon-lock.svg';
import OrdersSvg from 'assets/svg/icon-orders.svg';
import PencilAltSvg from 'assets/svg/icon-pencil-alt.svg';
import EditPencilSvg from 'assets/svg/icon-pencil-edit.svg';
import MakeStyleIconSvg from 'assets/svg/icon-make-style.svg';
// MinusSvg
import MinusSvg from 'assets/svg/icon-minus.svg';
import PhotoIconSvg from 'assets/svg/icon-photo.svg';
import PlusSvg from 'assets/svg/icon-plus.svg';
import PocketSvg from 'assets/svg/icon-outline-pocket.svg';
import PrinterSvg from 'assets/svg/icon-printer.svg';
import RightArrowSvg from 'assets/svg/icon-right-arrow.svg';
import RefreshSvg from 'assets/svg/refresh.svg';
import ScissorsSvg from 'assets/svg/icon-scissors.svg';
import SearchIconSvg from 'assets/svg/icon-search.svg';
import StepFinishedSvg from 'assets/svg/icon-step-finished.svg';
import StepNotStartedSvg from 'assets/svg/icon-step-not-started.svg';
import StepStartedSvg from 'assets/svg/icon-step-started.svg';
import TagSvg from 'assets/svg/icon-tag.svg';
import TaperLogoSvg from 'assets/svg/icon-taper-logo.svg';
import TooltipCaretSvg from 'assets/svg/icon-tooltip-caret.svg';
import TruckFilledSvg from 'assets/svg/icon-truck-filled.svg';
import TruckOutlineSvg from 'assets/svg/icon-truck-outline.svg';
import VerticalLineSvg from 'assets/svg/icon-vertical-line.svg';
import WarningSvg from 'assets/svg/icon-warning.svg';
import ChatSvg from 'assets/svg/chat.svg';
import UploadSvg from 'assets/svg/icon-cloud-upload.svg';
import DeleteSvg from 'assets/svg/icon-delete.svg';

export type IconType =
  | 'add-circle'
  | 'add'
  | 'archive'
  | 'card'
  | 'chat'
  | 'chevron-down'
  | 'chevron-up'
  | 'clock'
  | 'clipboard'
  | 'completed'
  | 'connector'
  | 'cross'
  | 'danger-filled'
  | 'danger-stroke'
  | 'duplicate'
  | 'edit-pencil-icon'
  | 'edit'
  | 'fabric'
  | 'info-filled'
  | 'info-stroke'
  | 'lock'
  | 'make-style'
  | 'minus'
  | 'orders'
  | 'pencil-alt'
  | 'photo'
  | 'plus'
  | 'pocket'
  | 'printer'
  | 'refresh'
  | 'right-arrow'
  | 'scissors'
  | 'search'
  | 'step-finished'
  | 'step-not-started'
  | 'step-started'
  | 'tag'
  | 'taper-logo'
  | 'tooltip-caret'
  | 'truck-filled'
  | 'truck-outline'
  | 'vertical-line'
  | 'warning'
  | 'upload'
  | 'delete';

type Props = {
  icon: IconType;
  className?: string;
  height?: number;
  width?: number;
};

const BASE_CLASSES = ['inline-block'];

export const Icon = memo(({ className, icon, height, width }: Props) => {
  // TODO: verify icons look as they should
  const fillIconClasses = cn(className, BASE_CLASSES, 'fill-current');
  const strokeIconClasses = cn(className, BASE_CLASSES, 'stroke-current');

  switch (icon) {
    case 'add-circle':
      return <AddCircleSvg className={strokeIconClasses} width={width || 20} height={height || 20} />;
    case 'add':
      return <AddSvg className={fillIconClasses} width={width || 20} height={height || 20} />;
    case 'archive':
      return <ArchiveSvg className={strokeIconClasses} width={width || 18} height={height || 20} />;
    case 'card':
      return <CardSvg className={fillIconClasses} width={width || 49} height={height || 48} />;
    case 'chat':
      return <ChatSvg className={strokeIconClasses} width={width || 24} height={height || 24} />;
    case 'chevron-down':
      return <ChevronDownSvg className={cn(className ?? 'stroke-2', BASE_CLASSES, 'stroke-current')} width={width || 16} height={height || 9} />;
    case 'chevron-up':
      return <ChevronUpSvg className={cn(className ?? 'stroke-2', BASE_CLASSES, 'stroke-current')} width={width || 16} height={height || 9} />;
    case 'clock':
      return <ClockSvg className={fillIconClasses} width={width || 16} height={height || 16} />;
    case 'clipboard':
      return <ClipboardOutlineSvg className={fillIconClasses} width={width || 16} height={height || 16} />;
    case 'completed':
      return <CompletedSvg className={fillIconClasses} width={width || 16} height={height || 16} />;
    case 'connector':
      return <ConnectorSvg className={fillIconClasses} width={width || 2} height={height || 32} />;
    case 'cross':
      return <CrossSvg className={strokeIconClasses} width={width || 8} height={height || 8} />;
    case 'danger-filled':
      return <DangerFilledSvg className={fillIconClasses} width={width || 16} height={height || 16} />;
    case 'danger-stroke':
      return <DangerStrokeSvg className={strokeIconClasses} width={width || 21} height={height || 21} />;
    case 'duplicate':
      return <DuplicateSvg className={strokeIconClasses} width={width || 24} height={height || 24} />;
    case 'edit-pencil-icon':
      return <EditPencilSvg className={fillIconClasses} width={width || 14} height={height || 14} />;
    case 'edit':
      return <EditSvg className={fillIconClasses} width={width || 16} height={height || 16} />;
    case 'fabric':
      return <FabricSvg className={fillIconClasses} width={width || 16} height={height || 16} />;
    case 'info-filled':
      return <InfoFilledSvg className={fillIconClasses} width={width || 16} height={height || 16} />;
    case 'info-stroke':
      return <InfoStrokeSvg className={strokeIconClasses} width={width || 20} height={height || 20} />;
    case 'lock':
      return <LockSvg className={strokeIconClasses} width={width || 14} height={height || 16} />;
    case 'make-style':
      return <MakeStyleIconSvg className={strokeIconClasses} width={width || 16} height={height || 16} />;
    case 'minus':
      return <MinusSvg className={fillIconClasses} width={width || 40} height={height || 40} />;
    case 'orders':
      return <OrdersSvg className={fillIconClasses} width={width || 48} height={height || 48} />;
    case 'pencil-alt':
      return <PencilAltSvg className={strokeIconClasses} width={width || 19} height={height || 19} />;
    case 'photo':
      return <PhotoIconSvg className={strokeIconClasses} width={width || 20} height={height || 20} />;
    case 'plus':
      return <PlusSvg className={fillIconClasses} width={width || 40} height={height || 40} />;
    case 'pocket':
      return <PocketSvg className={strokeIconClasses} width={width || 16} height={height || 16} />;
    case 'printer':
      return <PrinterSvg className={strokeIconClasses} width={width || 20} height={height || 20} />;
    case 'refresh':
      return <RefreshSvg className={strokeIconClasses} width={width || 16} height={height || 16} />;
    case 'right-arrow':
      return <RightArrowSvg className={fillIconClasses} width={width || 16} height={height || 16} />;
    case 'scissors':
      return <ScissorsSvg className={fillIconClasses} width={width || 16} height={height || 16} />;
    case 'search':
      return <SearchIconSvg className={cn(className ?? 'stroke-2', BASE_CLASSES, 'stroke-current')} width={width || 18} height={height || 18} />;
    case 'step-finished':
      return <StepFinishedSvg className={fillIconClasses} width={width || 32} height={height || 32} />;
    case 'step-not-started':
      return <StepNotStartedSvg className={fillIconClasses} width={width || 32} height={height || 32} />;
    case 'step-started':
      return <StepStartedSvg className={fillIconClasses} width={width || 32} height={height || 32} />;
    case 'tag':
      return <TagSvg className={strokeIconClasses} width={width || 20} height={height || 20} />;
    case 'taper-logo':
      return <TaperLogoSvg className={fillIconClasses} width={width || 64} height={height || 65} />;
    case 'tooltip-caret':
      return <TooltipCaretSvg className={fillIconClasses} width={width || 20} height={height || 12} />;
    case 'truck-filled':
      return <TruckFilledSvg className={fillIconClasses} width={width || 16} height={height || 16} />;
    case 'truck-outline':
      return <TruckOutlineSvg className={fillIconClasses} width={width || 21} height={height || 16} />;
    case 'vertical-line':
      return <VerticalLineSvg className={strokeIconClasses} width={width || 1} height={height || 28} />;
    case 'warning':
      return <WarningSvg className={strokeIconClasses} width={width || 20} height={height || 18} />;
    case 'upload':
      return <UploadSvg className={strokeIconClasses} width={width || 24} height={height || 24} />;
    case 'delete':
      return <DeleteSvg className={strokeIconClasses} width={width || 24} height={height || 24} />;
    default:
      return <></>;
  }
});
