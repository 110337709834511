import { Switch as HSwitch } from '@headlessui/react';
import cn from 'classnames';

type Props = {
  isChecked: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
};

export const Switch = (props: Props) => {
  const { isChecked, onChange, disabled } = props;

  return (
    <HSwitch
      disabled={disabled}
      checked={isChecked}
      onChange={onChange}
      className={cn(
        isChecked && !disabled ? 'bg-yellow-crayola-500' : 'bg-gray-200',
        'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-crayola-500'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={cn(
          isChecked ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
        )}
      >
        <span
          className={cn(
            isChecked ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
          )}
          aria-hidden="true"
        ></span>
        <span
          className={cn(
            isChecked ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
          )}
          aria-hidden="true"
        ></span>
      </span>
    </HSwitch>
  );
};
