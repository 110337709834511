import { getAuth, User, browserLocalPersistence, signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from 'firebase/auth';
import { firebaseService } from './firebase.service';

type OnAuthStateChangedParam = (firebaseUser: User | null) => void;
type OnIsTokenChangedParam = () => void;

class AuthService {
  getFirebaseUser(): Promise<User | null> {
    return new Promise((resolve) => {
      const unsubscribe = getAuth(firebaseService).onAuthStateChanged((firebaseUser) => {
        resolve(firebaseUser);
        unsubscribe();
      });
    });
  }

  getCurrentUser(): User | null {
    return getAuth(firebaseService).currentUser;
  }

  getToken(forceRefresh = true): Promise<string> {
    return new Promise((resolve, reject) => {
      getAuth(firebaseService).currentUser?.getIdToken(forceRefresh).then(resolve).catch(reject);
    });
  }

  onAuthStateChanged(callback: OnAuthStateChangedParam) {
    return getAuth(firebaseService).onAuthStateChanged(callback);
  }

  onIdTokenChanged(callback: OnIsTokenChangedParam) {
    return getAuth(firebaseService).onIdTokenChanged(callback);
  }

  signInWithEmailAndPassword(email: string, password: string): Promise<User> {
    return new Promise((resolve, reject) => {
      getAuth(firebaseService)
        .setPersistence(browserLocalPersistence)
        .then(() => signInWithEmailAndPassword(getAuth(), email, password))
        .then(() => this.getFirebaseUser())
        .then((firebaseUser) => (firebaseUser ? resolve(firebaseUser) : reject()))
        .catch(reject);
    });
  }

  sendResetPasswordEmail(email: string) {
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(getAuth(firebaseService), email).then(resolve).catch(reject);
    });
  }

  signOut() {
    return new Promise((resolve, reject) => {
      signOut(getAuth(firebaseService)).then(resolve).catch(reject);
    });
  }
}

export const authService = new AuthService();
