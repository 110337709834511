import { MaterialType } from '@graphql';
import { TYPE_OPTIONS_WITH_PREVIEW } from './constants';
import { CANVAS_FABRICS } from './material-constants';

export const hasOptionPreview = (typeCode: string, optionCode?: string) => {
  const previewOptions = TYPE_OPTIONS_WITH_PREVIEW[typeCode];
  if (!previewOptions) {
    return false;
  }
  return previewOptions.includes(optionCode);
};

export const hasMaterialPreview = (materialCode: string, materialType: MaterialType) => {
  if (materialType === MaterialType.Fabric) {
    return !!CANVAS_FABRICS[materialCode];
  }
  return false; // it does not apply
};
