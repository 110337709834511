import { ReactNode, useEffect, useRef } from 'react';
import { Router } from 'next/router';
import { TheHeader } from 'components';
import AppUpdate from 'components/AppUpdate';
import { BaseApp } from './BaseApp';
import { ToastContainer } from 'components/ToastContainer';

type Props = {
  children: ReactNode;
};

const AppLayout = ({ children }: Props) => {
  const mainRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      mainRef.current?.scrollTo(0, 0);
    });
  }, []);
  return (
    <BaseApp>
      <TheHeader />
      <div className="h-screen flex overflow-hidden bg-gray-100 pt-16 print:overflow-visible print:w-full print:h-full print:bg-white">
        <div className="flex flex-col w-0 flex-1 overflow-hidden print:overflow-visible">
          <main className="flex-1 relative overflow-y-auto focus:outline-none print:overflow-visible" ref={mainRef}>
            <AppUpdate />
            {children}
          </main>
        </div>

        <ToastContainer />
      </div>
    </BaseApp>
  );
};

export { AppLayout };
