import { Switch } from 'controls/Switch';
import { ReactElement, ReactNode } from 'react';
import cn from 'classnames';

type Props = {
  id: string;
  label: ReactNode;
  checkedOption: {
    value: string;
    label: string;
    image?: ReactElement;
  };
  options: {
    value: string;
    label: string;
    image?: ReactElement;
  }[];
  onChange: ({ value, label, image }: { value: string; label: string; image?: ReactElement }) => void;
  disabled?: boolean;
};

export const NewDesignFlowSwitch = ({ id, label, checkedOption, options, onChange, disabled }: Props) => {
  const newOption = options.find((opt) => opt.value !== checkedOption.value)!;
  return (
    <div
      onClick={() => !disabled && options.length === 2 && onChange(newOption)}
      id={id}
      className={cn('h-10 px-4 flex items-center justify-between', { 'cursor-pointer': !disabled })}
    >
      <span className={cn({ 'text-neutral-500': disabled, 'text-sm text-white font-medium rounded': !disabled })}>{label}</span>
      <div className="flex gap-4 p-2">
        <Switch isChecked={checkedOption?.value.toLowerCase().includes('v2') ?? false} disabled={disabled} />
      </div>
    </div>
  );
};
