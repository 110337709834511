import React from 'react';
import cn from 'classnames';
import { Icon } from 'components/Icon';

type Props = {
  className?: string;
  isLoading?: boolean;
  isCentred?: boolean;
};

const BASE_CLASSES = ['rounded-md', 'text-yellow-crayola-500'];

export const TaperLoading = ({ className, isLoading = true, isCentred = true }: Props) => {
  const classes = cn(className, BASE_CLASSES, {
    'animate-pulse': isLoading,
  });

  if (isCentred) {
    return (
      <div className="w-full h-96 flex items-center justify-center">
        <Icon icon="taper-logo" className={classes} />
      </div>
    );
  }

  return <Icon icon="taper-logo" className={classes} />;
};
