import React, { createContext, useEffect } from 'react';
import { gql } from '@apollo/client';
import { FulfilmentType, UseActiveSessionFragmentFragment, useUseActiveSessionQuery } from '@graphql';
import { useRouter } from 'next/router';
import { ErrorPanel } from 'components/ErrorPanel';

export type ActiveSessionInfo = {
  loading: boolean;
  activeSession: UseActiveSessionFragmentFragment;
  tailor: UseActiveSessionFragmentFragment['tailor'];
  tailorSlug: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  fulfilment: FulfilmentType;
  defaultShowroom: UseActiveSessionFragmentFragment['user']['defaultShowroom'];
  user: UseActiveSessionFragmentFragment['user'];
  defaultMeasurementUnit: UseActiveSessionFragmentFragment['tailor']['offeringConfig']['defaultMeasurementUnit'];
  shouldAllowRemake: boolean;
  shouldShowGarmentRenderer: boolean;
  hidePricingTabs?: string[];
};

export const ActiveSessionContext = createContext<ActiveSessionInfo | undefined>(undefined);

export const ActiveSessionProvider = ({ children }) => {
  const router = useRouter();
  const { data, loading, error } = useUseActiveSessionQuery({ fetchPolicy: 'no-cache' });

  const activeSession = {
    loading,
    activeSession: data?.activeSession,
    tailor: data?.activeSession.tailor,
    tailorSlug: data?.activeSession.tailor.slug,
    isAdmin: data?.activeSession.user.role === 'ADMIN' || false,
    isSuperAdmin: data?.activeSession.user.role === 'SUPER_ADMIN' || false,
    fulfilment: data?.activeSession.tailor.fulfilment,
    defaultShowroom: data?.activeSession.user.defaultShowroom,
    user: data?.activeSession.user,
    defaultMeasurementUnit: data?.activeSession.tailor.offeringConfig?.defaultMeasurementUnit,
    shouldAllowRemake: data?.activeSession.tailor.offeringConfig?.shouldAllowRemake,
    shouldShowGarmentRenderer: data?.activeSession.tailor.offeringConfig?.shouldShowGarmentRenderer,
    hidePricingTabs: data?.activeSession.tailor.offeringConfig?.hidePricingTabs,
  };

  useEffect(() => {
    if (error && error.networkError?.message?.includes('invalid refresh token')) {
      router.push(`/login?redirect=false`);
    }
  }, [error]);

  if (error) {
    return <ErrorPanel />;
  }

  return <ActiveSessionContext.Provider value={activeSession}>{data ? children : null}</ActiveSessionContext.Provider>;
};

ActiveSessionProvider.fragments = {
  activeSession: gql`
    fragment UseActiveSessionFragment on SessionResolverType {
      user {
        id
        role
        email
        firstName
        lastName
        state
        createdAt {
          formatted
          origin
        }
        defaultShowroom {
          id
          name
          customId
          externalId
          isActive
        }
        enforcedDesignFlowSelection
        showDesignFlowV2Toggle
        designFlowToggleName
      }
      tailor {
        slug
        name
        locale
        currency
        timezone
        logoUrl
        lightLogoUrl
        fulfilment
        config {
          availableFits
          availableFeatures
        }
        offeringConfig {
          defaultMeasurementUnit
          shouldAllowRemake
          shouldShowGarmentRenderer
          shouldAllowNewDesignFlow
          hidePricingTabs
        }
        defaultMarginPercent
      }
    }
  `,
};

ActiveSessionProvider.query = gql`
  ${ActiveSessionProvider.fragments.activeSession}
  query UseActiveSession {
    activeSession {
      ...UseActiveSessionFragment
    }
  }
`;
