import { GarmentCategory } from '@graphql';
import { DesignOption, GetImagesResponse } from './types';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { GET_IMAGES_PER_GARMENT_CATEGORY } from './image-bundler';
import React from 'react';
import cn from 'classnames';
import { imagesAreTheSame } from './utils';
import { Spinner } from 'components';

type Props = {
  baseUrl: string;
  designOptions: DesignOption[];
  garmentCategory: GarmentCategory;
  fabricCode?: string;
  liningCode?: string;
  hasConflicts: boolean;
  isDirty: boolean;
};

export const GarmentImages = React.memo(({ baseUrl, designOptions, garmentCategory, fabricCode, liningCode, isDirty, hasConflicts }: Props) => {
  const [prevImages, setPrevImages] = useState<GetImagesResponse['images']>([]);
  const [{ images, warnings }, setImageResponse] = useState<GetImagesResponse>({ images: [], warnings: [] });
  const [isImgLoading, setIsImgLoading] = useState(false);
  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const getImages = GET_IMAGES_PER_GARMENT_CATEGORY[garmentCategory];
    if (getImages) {
      const response = getImages(baseUrl, designOptions, fabricCode, liningCode);
      if (!imagesAreTheSame(images, response.images) && !hasConflicts) {
        setIsImgLoading(true);
        setPrevImages(images);
        setImageResponse(response);
      }
    }
  }, [garmentCategory, designOptions, fabricCode, liningCode, hasConflicts]);

  useLayoutEffect(() => {
    const domImages = Array.from(divRef.current.querySelectorAll<HTMLImageElement>('.preview-img'));
    if (domImages.every((i) => i.complete)) {
      setIsImgLoading(false);
    } else {
      setIsImgLoading(true);
    }
  }, [images]);

  const isLoading = isImgLoading || isDirty;
  const showPrevImage = isLoading || hasConflicts;

  return (
    <div ref={divRef} className="relative" style={{ minHeight: 384 }}>
      {images.map(({ key, url }, index) => (
        <img
          key={key}
          data-key={key}
          src={url}
          className={cn('preview-img inset-0', {
            absolute: index > 0 || showPrevImage,
            invisible: showPrevImage,
            'z-20': !showPrevImage,
            'z-10': showPrevImage,
          })}
          onLoad={() => {
            const domImages = Array.from(divRef.current.querySelectorAll<HTMLImageElement>('.preview-img'));
            if (domImages.every((i) => i.complete)) {
              setIsImgLoading(false);
            }
          }}
          onError={() => {
            if (!hasConflicts) {
              setImageResponse((prev) => ({
                ...prev,
                warnings: [...prev.warnings, { key, status: 'NOT_FOUND' }],
                images: prev.images.filter((i) => i.key !== key),
              }));
            }
            const domImages = Array.from(divRef.current.querySelectorAll<HTMLImageElement>(`.preview-img:not([data-key='${key}'])`));
            if (domImages.every((i) => i.complete)) {
              setIsImgLoading(false);
            }
          }}
        />
      ))}
      {showPrevImage && prevImages.map(({ key, url }, index) => <img key={key} src={url} className={cn({ 'absolute inset-0 z-20': index > 0 })} />)}
      {warnings.length > 0 && (
        <ul className="z-10 absolute top-1 left-1 text-red-500" style={{ fontSize: 9 }}>
          {warnings.map((m) => (
            <li key={m.key}>
              {m.key}: {m.status}
            </li>
          ))}
        </ul>
      )}
      {isImgLoading && !hasConflicts && (
        <div className="absolute z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Spinner className="w-24 h-24" />
        </div>
      )}
      {hasConflicts && (
        <span className="z-30 text-2xl text-orange-600 font-bold absolute origin-center rotate-45 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          Has conflicts
        </span>
      )}
    </div>
  );
});
