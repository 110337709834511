import { ReactNode, useEffect, useState } from 'react';
import { ActiveSessionProvider } from 'providers/active-session.provider';
import { useRouter } from 'next/router';
import { useAuth0 } from '@auth0/auth0-react';
import mixpanelService from 'services/mixpanel.service';
import { useActiveSessionQuery } from '@graphql';
import { useIntercomInitializer } from 'hooks/useIntercomInitializer';
import amplitudeService from 'services/amplitude.service';

type Props = {
  children: ReactNode;
};

const BaseApp = ({ children }: Props) => {
  const router = useRouter();
  const { isLoading: isAuthenticating, isAuthenticated, error: auth0Error } = useAuth0();
  const { data, error: sessionError } = useActiveSessionQuery({ fetchPolicy: 'cache-first', skip: !isAuthenticated });
  const [isLoading, setIsLoading] = useState(true);

  useIntercomInitializer(data?.activeSession);

  useEffect(() => {
    if (data?.activeSession) {
      amplitudeService.identify(data.activeSession);
    }
  }, [data?.activeSession]);

  const error = auth0Error || sessionError;
  useEffect(() => {
    if (error) {
      let errorMessage = error.message;
      if (errorMessage === 'client requires organization membership, but user does not belong to any organization') {
        errorMessage = `User does not belong to an organisation`;
      }
      router.push(`/login?redirect=false&error=${errorMessage}`);
      return;
    } else {
      if (isAuthenticating) return;
      if (!isAuthenticated) {
        router.push('/login');
        return;
      }
    }

    setIsLoading(false);
  }, [isAuthenticating, isAuthenticated]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const route = router.route;
      const queryParams = router.query;
      //Send track event when new pages is loaded
      mixpanelService.trackPage({ url, route, ...queryParams });
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [data, router.events]);

  if (isLoading) {
    return null;
  }

  return <ActiveSessionProvider>{children}</ActiveSessionProvider>;
};

export { BaseApp };
