export const CANVAS_FABRICS = {
  CSF31: 'CSF31',
  'CSF36-T': 'CSF36-T',
  JX026: 'INSTITCHU_Fabric_Swatch_JX026',
  JX054: 'INSTITCHU_Fabric_Swatch_JX054',
  JX071: 'INSTITCHU_Fabric_Swatch_JX071',
  JX074: 'INSTITCHU_Fabric_Swatch_JX074',
  'JX083-T': 'INSTITCHU_Fabric_Swatch_JX083-T',
  JX113: 'INSTITCHU_Fabric_Swatch_JX113',
  JX114: 'INSTITCHU_Fabric_Swatch_JX114',
  JX121: 'INSTITCHU_Fabric_Swatch_JX121',
  JX122: 'INSTITCHU_Fabric_Swatch_JX122',
  JX123: 'INSTITCHU_Fabric_Swatch_JX123',
  JX126: 'INSTITCHU_Fabric_Swatch_JX126',
  JX129: 'INSTITCHU_Fabric_Swatch_JX129',
  JX130: 'INSTITCHU_Fabric_Swatch_JX130',
  JX134: 'INSTITCHU_Fabric_Swatch_JX134',
  JX144: 'INSTITCHU_Fabric_Swatch_JX144',
  JX146: 'INSTITCHU_Fabric_Swatch_JX146',
  JX149: 'INSTITCHU_Fabric_Swatch_JX149',
  JX150: 'INSTITCHU_Fabric_Swatch_JX150',
  JX151: 'INSTITCHU_Fabric_Swatch_JX151',
  JX155: 'INSTITCHU_Fabric_Swatch_JX155',
  JX160: 'INSTITCHU_Fabric_Swatch_JX160',
  JX161: 'INSTITCHU_Fabric_Swatch_JX161',
  JX162: 'INSTITCHU_Fabric_Swatch_JX162',
  JX3160: 'INSTITCHU_Fabric_Swatch_JX3160',
  JX3161: 'INSTITCHU_Fabric_Swatch_JX3161',
  JX3162: 'INSTITCHU_Fabric_Swatch_JX3162',
  JX3163: 'INSTITCHU_Fabric_Swatch_JX3163',
  JX3166: 'INSTITCHU_Fabric_Swatch_JX3166', // issues
  JX3167: 'INSTITCHU_Fabric_Swatch_JX3167', // issues
  JX3168: 'INSTITCHU_Fabric_Swatch_JX3168',
  JX3169: 'INSTITCHU_Fabric_Swatch_JX3169',
  JX3182: 'INSTITCHU_Fabric_Swatch_JX3182',
  TR2356: 'INSTITCHU_Fabric_Swatch_TR2356',
  TR2357: 'INSTITCHU_Fabric_Swatch_TR2357',
  TR2402: 'INSTITCHU_Fabric_Swatch_TR2402',
  TR2432: 'INSTITCHU_Fabric_Swatch_TR2432',
  TR2433: 'INSTITCHU_Fabric_Swatch_TR2433',
  TR2434: 'INSTITCHU_Fabric_Swatch_TR2434',
  TR2491: 'INSTITCHU_Fabric_Swatch_TR2491',
  TR2580: 'INSTITCHU_Fabric_Swatch_TR2580',
  TR2582: 'INSTITCHU_Fabric_Swatch_TR2582',
  TR2583: 'INSTITCHU_Fabric_Swatch_TR2583',
  TR2584: 'INSTITCHU_Fabric_Swatch_TR2584',
  TRA001: 'INSTITCHU_Fabric_Swatch_TRA001',
  TRA002: 'INSTITCHU_Fabric_Swatch_TRA002',
  TRA005: 'INSTITCHU_Fabric_Swatch_TRA005',
  TRA009: 'INSTITCHU_Fabric_Swatch_TRA009',
  TRA018: 'INSTITCHU_Fabric_Swatch_TRA018',
  TRA2161: 'INSTITCHU_Fabric_Swatch_TRA2161',
  TRA2167: 'INSTITCHU_Fabric_Swatch_TRA2167',
  TRA2168: 'INSTITCHU_Fabric_Swatch_TRA2168',
  TRA2172: 'INSTITCHU_Fabric_Swatch_TRA2172',
  TRA2174: 'INSTITCHU_Fabric_Swatch_TRA2174',
  TRA2686: 'INSTITCHU_Fabric_Swatch_TRA2686',
  TRA2689: 'TRA2689', // issues
  TRA2690: 'TRA2690', // issues
  TRA2691: 'INSTITCHU_Fabric_Swatch_TRA2691',
  TRA2693: 'INSTITCHU_Fabric_Swatch_TRA2693',
  TRA2697: 'INSTITCHU_Fabric_Swatch_TRA2697',
  TRA2698: 'INSTITCHU_Fabric_Swatch_TRA2698',
  TRA2699: 'INSTITCHU_Fabric_Swatch_TRA2699',
  TRA2701: 'TRA2701', // issues
  TRA2702: 'INSTITCHU_Fabric_Swatch_TRA2702',
  TRA2703: 'INSTITCHU_Fabric_Swatch_TRA2703',
  TRA2704: 'TRA2704', // issues
  TRA2706: 'INSTITCHU_Fabric_Swatch_TRA2706',
  TRA2707: 'INSTITCHU_Fabric_Swatch_TRA2707',
  TTR2072: 'INSTITCHU_Fabric_Swatch_TTR2072',
  TTR2075: 'INSTITCHU_Fabric_Swatch_TTR2075',
  TTR2585: 'INSTITCHU_Fabric_Swatch_TTR2585',
  TTR2589: 'INSTITCHU_Fabric_Swatch_TTR2589',
  TTR2590: 'INSTITCHU_Fabric_Swatch_TTR2590',
  TTV2077: 'INSTITCHU_Fabric_Swatch_TTV2077', // issues
  TTV2079: 'INSTITCHU_Fabric_Swatch_TTV2079', // issues
  TTV2081: 'INSTITCHU_Fabric_Swatch_TTV2081', // issues
  TTV2082: 'INSTITCHU_Fabric_Swatch_TTV2082', // issues
  TTV2092: 'INSTITCHU_Fabric_Swatch_TTV2092',
  TTV2096: 'INSTITCHU_Fabric_Swatch_TTV2096',
  TTV2097: 'INSTITCHU_Fabric_Swatch_TTV2097',
  TTV2098: 'INSTITCHU_Fabric_Swatch_TTV2098',
  TTV2100: 'INSTITCHU_Fabric_Swatch_TTV2100',
  TTV2101: 'INSTITCHU_Fabric_Swatch_TTV2101',
  TTV2102: 'INSTITCHU_Fabric_Swatch_TTV2102',
  TTV2103: 'INSTITCHU_Fabric_Swatch_TTV2103',
  TTV2104: 'INSTITCHU_Fabric_Swatch_TTV2104',
  TTV2105: 'INSTITCHU_Fabric_Swatch_TTV2105',
  TTV2106: 'INSTITCHU_Fabric_Swatch_TTV2106',
  TTV2119: 'INSTITCHU_Fabric_Swatch_TTV2119',
  TTV2126: 'INSTITCHU_Fabric_Swatch_TTV2126',
  TTV2132: 'INSTITCHU_Fabric_Swatch_TTV2132',
  'JX009-T': 'JX009-T',
  JX015: 'JX015',
  JX016: 'JX016',
  'JX017-T': 'JX017-T',
  JX020: 'JX020',
  JX022: 'JX022',
  'JX041-T': 'JX041-T',
  'JX043-T': 'JX043-T',
  'JX048-T': 'JX048-T',
  JX052: 'JX052',
  JX055: 'JX055',
  'JX064-T': 'JX064-T',
  JX072: 'JX072',
  JX081: 'JX081',
  JX090: 'JX090',
  JX092: 'JX092',
  JX109: 'INSTITCHU_Fabric_Swatch_JX109',
  JX110: 'JX110',
  JX112: 'JX112',
  JX115: 'JX115',
  JX124: 'JX124',
  JX125: 'JX125',
  JX128: 'JX128',
  JX132: 'JX132',
  JX133: 'JX133',
  JX136: 'JX136',
  JX138: 'JX138',
  JX142: 'JX142',
  JX145: 'JX145',
  JX147: 'JX147',
  JX154: 'JX154',
  JX156: 'JX156',
  JX157: 'JX157',
  JX158: 'JX158',
  JX159: 'JX159',
  JX3139: 'JX3139',
  JX3145: 'JX3145',
  JX3150: 'JX3150',
  JX3153: 'JX3153',
  JX3154: 'JX3154',
  JX3156: 'JX3156',
  JX3173: 'JX3173',
  JX3176: 'JX3176',
  JX3177: 'JX3177',
  JX3180: 'JX3180',
  JX3181: 'JX3181',
  JX3183: 'JX3183',
  JX3184: 'JX3184',
  JX3185: 'JX3185',
  JX3187: 'JX3187',
  JX3188: 'JX3188',
  JX003: 'Jx003',
  JX006: 'Jx006',
  JX007: 'Jx007', // issues
  JX008: 'Jx008',
  JX021: 'Jx021',
  JX030: 'Jx030',
  'JX046-T': 'Jx046-T',
  JX049: 'Jx049',
  JX111: 'Jx111',
  JX116: 'Jx116',
  JX117: 'Jx117',
  JX119: 'Jx119',
  JX127: 'Jx127',
  JX131: 'Jx131',
  JX135: 'Jx135',
  JX139: 'Jx139',
  JX140: 'Jx140',
  JX141: 'Jx141',
  JX143: 'Jx143',
  JX148: 'Jx148',
  JX163: 'Jx163',
  JX3138: 'Jx3138',
  JX3144: 'Jx3144',
  JX3146: 'Jx3146',
  JX3151: 'Jx3151',
  JX3152: 'Jx3152',
  JX3164: 'Jx3164',
  JX3170: 'Jx3170',
  JX3174: 'Jx3174',
  JX3179: 'Jx3179',
  TR2177: 'TR2177', // issues
  //TR2402: 'TR2402', (duplicated)
  TR2435: 'TR2435',
  TR2436: 'TR2436',
  TR2437: 'TR2437',
  TR2581: 'TR2581',
  //TR2583: 'TR2583', (duplicated)
  TRA007: 'TRA007',
  TRA010: 'TRA010',
  TRA019: 'TRA019',
  TRA021: 'TRA021',
  TRA024: 'TRA024',
  TRA032: 'TRA032',
  TRA044: 'TRA044',
  TRA045: 'TRA045',
  TRA046: 'TRA046',
  TRA2163: 'TRA2163',
  TRA2164: 'TRA2164',
  TRA2169: 'TRA2169',
  TRA2171: 'TRA2171',
  TRA2173: 'TRA2173',
  TRA2175: 'TRA2175',
  TRA2286: 'TRA2286',
  TRA2287: 'TRA2287',
  TRA2695: 'TRA2695',
  TRA2696: 'TRA2696',
  TTR2088: 'TTR2088',
  TTR2586: 'TTR2586',
  TTR2591: 'TTR2591',
  TTR2592: 'TTR2592',
  TTR2593: 'TTR2593',
  TTR2594: 'TTR2594',
  TTV2076: 'TTV2076', // issues
  TTV2083: 'TTV2083', // issues
  TTV2084: 'TTV2084', // issues
  TTV2085: 'TTV2085', // issues
  TTV2086: 'TTV2086', // issues
  TTV2087: 'TTV2087', // issues
  TTV2090: 'TTV2090',
  TTV2108: 'TTV2108',
  TTV2110: 'TTV2110',
  TTV2111: 'TTV2111',
  TTV2112: 'TTV2112',
  TTV2113: 'TTV2113',
  TTV2114: 'TTV2114',
  TTV2115: 'TTV2115',
  TTV2117: 'TTV2117',
  TTV2118: 'TTV2118',
  TTV2120: 'TTV2120',
  TTV2121: 'TTV2121',
  TTV2123: 'TTV2123',
  TTV2125: 'TTV2125',
  TTV2127: 'TTV2127',
  TTV2134: 'TTV2134',
  TTV2135: 'INSTITCHU_Fabric_Swatch_TTV2135',
  TTV2136: 'INSTITCHU_Fabric_Swatch_TTV2136',
  TTV2142: 'INSTITCHU_Fabric_Swatch_TTV2142',
  TTV2143: 'TTV2143',
  TTV2144: 'TTV2144',
  TTV2145: 'INSTITCHU_Fabric_Swatch_TTV2145',
  TTV2146: 'TTV2146',
  TTV2147: 'TTV2147',
  TTV2148: 'TTV2148',
  TTV2149: 'TTV2149',
  TTV2150: 'TTV2150',
  TTV2151: 'TTV2151',
  TTV2154: 'INSTITCHU_Fabric_Swatch_TTV2154',
  TTV2155: 'TTV2155',
  TTV2157: 'INSTITCHU_Fabric_Swatch_TTV2157',
  TTV2158: 'TTV2158',
  TTV2159: 'TTV2159',
  TTV2598: 'TTV2598',
  TTV2599: 'INSTITCHU_Fabric_Swatch_TTV2599',
  TTV2600: 'TTV2600',
  TTV2602: 'TTV2602',
  TTV2603: 'TTV2603',
  TTV2608: 'TTV2608',
  TTV2610: 'TTV2610',
  TTV2611: 'TTV2611',
  TTV2612: 'TTV2612',
  TTV2613: 'INSTITCHU_Fabric_Swatch_TTV2613',
  TTV2614: 'TTV2614',
  TTV2615: 'INSTITCHU_Fabric_Swatch_TTV2615',
  TTV2616: 'TTV2616',
  TTV2617: 'TTV2617',
  TTV2618: 'TTV2618',
  TTV3155: 'TTV3155', // issues
  TR2400: 'Tr2400',
  TR2401: 'Tr2401',
  TR9065: 'Tr9065',
  TRA004: 'Tra004',
  TRA015: 'Tra015',
  TRA022: 'TRA022',
  TRA025: 'Tra025',
  TRA034: 'Tra034',
  TRA035: 'Tra035',
  TRA036: 'Tra036',
  TRA041: 'Tra041',
  TRA043: 'Tra043',
  TRA047: 'Tra047',
  TRA048: 'Tra048',
  TRA2117: 'Tra2117', // issues
  TRA2160: 'Tra2160',
  TRA2166: 'Tra2166',
  TRA2170: 'Tra2170',
  TRA2687: 'Tra2687',
  TRA2688: 'Tra2688',
  TRA2692: 'Tra2692',
  TRA2694: 'Tra2694',
  TRA2700: 'Tra2700',
  TTR2587: 'Ttr2587',
  TTV2089: 'Ttv2089',
  TTV2107: 'Ttv2107',
  TTV2122: 'Ttv2122',
  TTV2128: 'Ttv2128',
  TTV2131: 'Ttv2131',
  TTV2133: 'Ttv2133',
  TTV2137: 'Ttv2137',
  TTV2140: 'Ttv2140',
  TTV2152: 'TTV2152',
  TTV2604: 'Ttv2604',
  JX002: 'jx002',
  JX3175: 'jx3175',
  JX3178: 'jx3178',
  TRA2165: 'tra2165',
  // bbindia fabrics
  BB1153: 'INSTITCHU_Fabric_Swatch_TR2356',
  BB1155: 'INSTITCHU_Fabric_Swatch_TR2357',
  BB1157: 'INSTITCHU_Fabric_Swatch_TR2402',
  BB1143: 'INSTITCHU_Fabric_Swatch_TR2432',
  BB1144: 'INSTITCHU_Fabric_Swatch_TR2433',
  BB1148: 'INSTITCHU_Fabric_Swatch_TR2434',
  BB1146: 'TR2435',
  BB1145: 'TR2436',
  BB1147: 'TR2437',
  BB1158: 'INSTITCHU_Fabric_Swatch_TR2491',
  BB1001: 'INSTITCHU_Fabric_Swatch_TRA001',
  BB1002: 'INSTITCHU_Fabric_Swatch_TRA002',
  BB1004: 'INSTITCHU_Fabric_Swatch_TRA005',
  BB1005: 'TRA007',
  BB1007: 'INSTITCHU_Fabric_Swatch_TRA009',
  BB1008: 'TRA010',
  BB1012: 'INSTITCHU_Fabric_Swatch_TRA018',
  BB1013: 'TRA019',
  BB1015: 'TRA021',
  BB1016: 'TRA022',
  BB1017: 'TRA024',
  BB1019: 'TRA032',
  BB1026: 'TRA044',
  BB1027: 'TRA045',
  BB1028: 'TRA046',
  BB1032: 'INSTITCHU_Fabric_Swatch_TRA2161',
  BB1034: 'TRA2163',
  BB1035: 'TRA2164',
  BB1038: 'INSTITCHU_Fabric_Swatch_TRA2167',
  BB1039: 'INSTITCHU_Fabric_Swatch_TRA2168',
  BB1040: 'TRA2169',
  BB1042: 'TRA2171',
  BB1043: 'INSTITCHU_Fabric_Swatch_TRA2172',
  BB1044: 'TRA2173',
  BB1045: 'INSTITCHU_Fabric_Swatch_TRA2174',
  BB1046: 'TRA2175',
  BB1052: 'TRA2286',
  BB1053: 'TRA2287',
  BB1055: 'INSTITCHU_Fabric_Swatch_TTR2072',
  BB1058: 'INSTITCHU_Fabric_Swatch_TTR2075',
  BB1071: 'TTR2088',
  BB1073: 'TTV2090',
  BB1075: 'INSTITCHU_Fabric_Swatch_TTV2092',
  BB1079: 'INSTITCHU_Fabric_Swatch_TTV2096',
  BB1080: 'INSTITCHU_Fabric_Swatch_TTV2097',
  BB1081: 'INSTITCHU_Fabric_Swatch_TTV2098',
  BB1083: 'INSTITCHU_Fabric_Swatch_TTV2100',
  BB1084: 'INSTITCHU_Fabric_Swatch_TTV2101',
  BB1085: 'INSTITCHU_Fabric_Swatch_TTV2102',
  BB1086: 'INSTITCHU_Fabric_Swatch_TTV2103',
  BB1087: 'INSTITCHU_Fabric_Swatch_TTV2104',
  BB1088: 'INSTITCHU_Fabric_Swatch_TTV2105',
  BB1089: 'INSTITCHU_Fabric_Swatch_TTV2106',
  BB1091: 'TTV2108',
  BB1093: 'TTV2110',
  BB1094: 'TTV2111',
  BB1095: 'TTV2112',
  BB1096: 'TTV2113',
  BB1097: 'TTV2114',
  BB1098: 'TTV2115',
  BB1100: 'TTV2117',
  BB1101: 'TTV2118',
  BB1102: 'INSTITCHU_Fabric_Swatch_TTV2119',
  BB1103: 'TTV2120',
  BB1104: 'TTV2121',
  BB1106: 'TTV2123',
  BB1108: 'TTV2125',
  BB1109: 'INSTITCHU_Fabric_Swatch_TTV2126',
  BB1110: 'TTV2127',
  BB1115: 'INSTITCHU_Fabric_Swatch_TTV2132',
  BB1117: 'TTV2134',
  BB1118: 'INSTITCHU_Fabric_Swatch_TTV2135',
  BB1119: 'INSTITCHU_Fabric_Swatch_TTV2136',
  BB1125: 'INSTITCHU_Fabric_Swatch_TTV2142',
  BB1126: 'TTV2143',
  BB1127: 'TTV2144',
  BB1128: 'INSTITCHU_Fabric_Swatch_TTV2145',
  BB1129: 'TTV2146',
  BB1130: 'TTV2147',
  BB1131: 'TTV2148',
  BB1132: 'TTV2149',
  BB1133: 'TTV2150',
  BB1134: 'TTV2151',
  BB1135: 'TTV2152',
  BB1137: 'INSTITCHU_Fabric_Swatch_TTV2154',
  BB1138: 'TTV2155',
  BB1140: 'INSTITCHU_Fabric_Swatch_TTV2157',
  BB1141: 'TTV2158',
  BB1142: 'TTV2159',
};

export const CANVAS_LINING = {
  TR133: 'TR133 ',
  TR135: 'TR135 ',
  TR136: 'TR136 ',
  TR137: 'TR137 ',
  TR138: 'TR138 ',
  TR139: 'TR139 ',
  TR142: 'TR142 ',
  TR144: 'TR144 ',
  TR146: 'TR146 ',
  TR148: 'TR148',
  TR149: 'TR149 ',
  TR150: 'TR150 ',
  TR151: 'TR151 ',
  TR166: 'TR166 ',
  TR167: 'TR167 ',
  TR168: 'TR168 ',
  TR169: 'TR169 ',
  TR170: 'TR170 ',
  TR171: 'TR171',
  TR210: 'TR210 ',
  TR211: 'TR211 ',
  TR212: 'TR212 ',
  TR214: 'TR214 ',
  TR216: 'TR216 ',
  TR219: 'TR219 ',
  TR220: 'TR220 ',
  TR228: 'TR228 ',
  TR229: 'TR229',
  TR230: 'TR230 ',
  TR252: 'TR252 ',
  TR271: 'TR271 ',
  TR272: 'TR272 ',
  TR273: 'TR273 ',
  TR274: 'TR274',
  TR275: 'TR275',
  TR276: 'TR276 ',
  TR277: 'TR277 ',
  TR278: 'TR278 ',
  TR279: 'TR279 ',
  TR280: 'TR280 ',
  TR281: 'TR281 ',
  TR282: 'TR282 ',
  TR368: 'TR368',
  TR404: 'TR404',
  TR412: 'TR412',
  TR413: 'TR413',
  TR425: 'TR425',
  TR649: 'TR649',
  TR650: 'TR650',
  TR651: 'TR651',
  TR652: 'TR652',
  TR653: 'TR653',
  TR654: 'TR654',
  TR655: 'TR655',
  TR656: 'TR656',
  TR659: 'TR659',
  TR661: 'TR661',
  TR662: 'TR662',
  TR663: 'TR663',
  TR664: 'TR664',
  TR665: 'TR665',
  TR666: 'TR666',
  TR667: 'TR667',
  TR668: 'TR668',
  TR669: 'TR669',
  TR730: 'TR730',
  TR795: 'TR795',
  TR800: 'TR800',
  TR801: 'TR801',
  TR848: 'TR848',
  TR849: 'TR849',
  TR873: 'TR873',
  TR878: 'TR878',
  TR879: 'TR879',
  TR880: 'TR880',
  TR881: 'TR881',
  TR882: 'TR882',
  TR887: 'TR887',
  TR888: 'TR888',
  TR889: 'TR889',
  TR890: 'TR890',
  TR891: 'TR891',
  TR892: 'TR892',
  TR893: 'TR893',
  TR894: 'TR894',
  TR895: 'TR895',
  TR896: 'TR896',
  TR897: 'TR897',
  TR898: 'TR898',
  TR899: 'TR899',
  TR900: 'TR900',
  TR901: 'TR901',
  TR902: 'TR902',
  TR903: 'TR903',
  TR904: 'TR904',
  TR905: 'TR905',
  TR906: 'TR906',
  TR907: 'TR907',
  TR908: 'TR908',
  TR909: 'TR909',
  TR910: 'TR910',
  TR911: 'TR911',
  TR912: 'TR912',
  TR913: 'TR913',
  TR914: 'TR914',
  TR915: 'TR915',
  TR916: 'TR916',
  TR918: 'TR918',
  TR919: 'TR919',
  TR920: 'TR920',
  TR921: 'TR921',
  TR926: 'TR926',
  TR933: 'TR933',
  TR940: 'TR940',
  // bbindia linings
  BBL002: 'TR135 ',
  BBL003: 'TR136 ',
  BBL004: 'TR137 ',
  BBL005: 'TR138 ',
  BBL006: 'TR139 ',
  BBL008: 'TR142 ',
  BBL009: 'TR144 ',
  BBL010: 'TR146 ',
  BBL011: 'TR148',
  BBL012: 'TR149 ',
  BBL013: 'TR150 ',
  BBL014: 'TR151 ',
  BBL015: 'TR166 ',
  BBL016: 'TR167 ',
  BBL017: 'TR168 ',
  BBL018: 'TR169 ',
  BBL019: 'TR170 ',
  BBL020: 'TR171',
  BBL023: 'TR210 ',
  BBL024: 'TR211 ',
  BBL025: 'TR212 ',
  BBL026: 'TR214 ',
  BBL027: 'TR216 ',
  BBL028: 'TR219 ',
  BBL029: 'TR220 ',
  BBL030: 'TR228 ',
  BBL031: 'TR229',
  BBL032: 'TR230 ',
  BBL033: 'TR252 ',
  BBL034: 'TR271 ',
  BBL035: 'TR272 ',
  BBL036: 'TR273 ',
  BBL037: 'TR274',
  BBL038: 'TR275',
  BBL039: 'TR276 ',
  BBL040: 'TR277 ',
  BBL041: 'TR278 ',
  BBL042: 'TR279 ',
  BBL043: 'TR280 ',
  BBL044: 'TR281 ',
  BBL045: 'TR282 ',
  BBL046: 'TR368',
  BBL056: 'TR404',
  BBL057: 'TR412',
  BBL058: 'TR413',
  BBL061: 'TR425',
  BBL067: 'TR649',
  BBL068: 'TR650',
  BBL069: 'TR651',
  BBL070: 'TR652',
  BBL071: 'TR653',
  BBL072: 'TR654',
  BBL073: 'TR655',
  BBL074: 'TR656',
  BBL077: 'TR659',
  BBL078: 'TR661',
  BBL079: 'TR662',
  BBL080: 'TR663',
  BBL081: 'TR664',
  BBL082: 'TR665',
  BBL083: 'TR666',
  BBL084: 'TR667',
  BBL085: 'TR668',
  BBL086: 'TR669',
  BBL090: 'TR848',
  BBL091: 'TR849',
  BBL105: 'TR873',
  BBL106: 'TR882',
  BBL109: 'TR892',
  BBL110: 'TR894',
  BBL111: 'TR895',
  BBL112: 'TR896',
  BBL113: 'TR897',
  BBL114: 'TR898',
  BBL115: 'TR899',
  BBL116: 'TR900',
  BBL117: 'TR901',
  BBL118: 'TR902',
  BBL119: 'TR903',
  BBL120: 'TR904',
  BBL121: 'TR905',
  BBL122: 'TR909',
  BBL123: 'TR910',
  BBL124: 'TR911',
  BBL125: 'TR912',
  BBL126: 'TR913',
  BBL127: 'TR914',
  BBL128: 'TR915',
  BBL129: 'TR916',
  BBL131: 'TR918',
  BBL132: 'TR919',
  BBL133: 'TR920',
  BBL134: 'TR921',
  BBL137: 'TR926',
  BBL141: 'TR940',
};
