import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

import { RightModal } from 'components';
import { Button } from 'modules/common';
import { Icon } from 'components';
import { useGetCreateAlterationOrdersQuery, OrderItemStatus } from '@graphql';
import mixpanelService from 'services/mixpanel.service';

interface Option {
  readonly label: string;
  readonly value: string;
}

type Props = {
  orderId?: string;
};

export const CreateNewAlterationButton = ({ orderId }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Option>();
  const {
    data,
    loading: queryLoading,
    error,
  } = useGetCreateAlterationOrdersQuery({
    variables: { orderItemStatus: [OrderItemStatus.LeftFactory, OrderItemStatus.Shipped, OrderItemStatus.Delivered] },
    fetchPolicy: 'cache-first',
  });
  const router = useRouter();
  const loading = queryLoading;
  const orderOptions = data?.orders?.items
    .map((order) => ({
      value: order.id,
      label: `${order.customId} - ${order.customer.fullName}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (!modalOpen) setSelectedOrder(null);
  }, [modalOpen]);

  const handleCreateAlteration = async () => {
    try {
      const alterationOrderId = orderId || selectedOrder.value;
      mixpanelService.track('ALTERATION_CTA_MODAL_BUTTON', { orderId: alterationOrderId });
      router.push(`/alterations/new?orderId=${alterationOrderId}`);
    } catch {
      toast.error("Couldn't create new alteration.");
    }
    setModalOpen(false);
  };

  return (
    <>
      <Button onClick={() => (orderId ? handleCreateAlteration() : setModalOpen(true))}>
        <Icon icon="add-circle" className="h-4" />
        <span className="hidden xl:inline-block ml-1">New alteration</span>
      </Button>
      <RightModal isOpen={modalOpen} setIsOpen={setModalOpen}>
        <div className="font-semibold text-center text-lg mb-6">Create new alteration</div>
        <Select
          className="text-sm"
          placeholder={loading ? 'Loading...' : error ? 'Error' : 'Assign an order'}
          value={selectedOrder}
          onChange={(value) => setSelectedOrder(value)}
          options={orderOptions}
          styles={{
            control: (base, state) => ({
              ...base,
              border: state.isFocused ? '2px solid #6b7280' : '1px solid #d1d5db',
              boxShadow: 'none',
              '*': {
                boxShadow: 'none !important',
              },
              '&:hover': {
                border: '2px solid #6b7280',
              },
              borderRadius: '6px',
              padding: '2px 0',
            }),
            menuPortal: (base) => ({ ...base, zIndex: 50 }),
          }}
          menuPortalTarget={document.body}
          isDisabled={loading || !!error}
          isSearchable={true}
        />
        <div className="flex justify-between mt-6">
          <Button variant="neutral" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <div className="flex gap-2">
            <Button onClick={handleCreateAlteration} isDisabled={loading || !!error || !selectedOrder}>
              Create Alteration
            </Button>
          </div>
        </div>
      </RightModal>
    </>
  );
};

CreateNewAlterationButton.query = gql`
  query GetCreateAlterationOrders($orderItemStatus: [OrderItemStatus!]) {
    orders(orderItemStatus: $orderItemStatus) {
      items {
        id
        customId
        customer {
          fullName
        }
      }
    }
  }
`;
