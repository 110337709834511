import { gql } from '@apollo/client';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon, DuplicateIcon, PencilAltIcon, TrashIcon, CheckIcon } from '@heroicons/react/solid';
import cn from 'classnames';
import { useRouter } from 'next/router';
import React, { Fragment, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { OrderPageQuery, useDeleteOrderItemMutation, useDuplicateOrderItemMutation } from '@graphql';
import { Icon, RightModal, LinkInPopup } from 'components';
import { Button } from 'modules/common';
import { isFinished } from 'modules/orderItem/orderItem.helpers';
import mixpanelService from 'services/mixpanel.service';
import { useActiveSession } from 'hooks/useActiveSessionContext';

interface Props {
  className?: string;
  orderItem: OrderPageQuery['order']['items'][0];
  locked?: boolean;
}

export const OrderItemMenuCell = ({ className = '', orderItem, locked = false }: Props) => {
  const router = useRouter();
  const { orderId } = router.query;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [duplicatesList, setDuplicatesList] = useState([]);
  const itemStatus = useMemo(() => orderItem?.itemStatus.key, [orderItem?.itemStatus]);

  const [deleteOrderItem, { loading: isDeleting }] = useDeleteOrderItemMutation({
    refetchQueries: ['OrderPage'],
  });
  const [duplicateOrderItem, { loading: isDuplicating }] = useDuplicateOrderItemMutation({
    refetchQueries: ['OrderPage'],
  });

  const onDelete = async () => {
    try {
      await deleteOrderItem({ variables: { orderId: String(orderId), orderItemId: orderItem.id } });
      toast.success('Item deleted.');
    } catch (e) {
      console.error(e);
      toast.error(`${e?.message ?? `Item couldn't be deleted. Please try again.`}`, { autoClose: false });
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const onDuplicate = async () => {
    try {
      await duplicateOrderItem({ variables: { orderId: String(orderId), orderItemId: orderItem.id, orderItemDesignIds: duplicatesList } });
      mixpanelService.track('ORDER_ITEM_DUPLICATE', {
        orderId,
        orderItemId: orderItem.id,
        productCategory: orderItem.productCategory,
        name: orderItem.name,
      });
      toast.success('Items duplicated.');
    } catch (e) {
      console.error(e);
      toast.error(`${e?.message ?? `Items couldn't be duplicated. Please try again.`}`, { autoClose: false });
    }
  };

  const updateDuplicatesList = (designId: string) => {
    const i = duplicatesList.indexOf(designId);
    const list = i > -1 ? [...duplicatesList.slice(0, i), ...duplicatesList.slice(i + 1)] : [...duplicatesList, designId];

    setDuplicatesList(list);
  };

  const canDuplicate = useMemo(() => {
    const isRemake = !!orderItem?.remake?.remakeReason;
    return !isRemake && orderItem?.designs?.some((d) => d.hasSubTemplate);
  }, [orderItem]);

  const { tailor: { offeringConfig: { shouldAllowNewDesignFlow } = {} } = {} } = useActiveSession();
  const { user: { enforcedDesignFlowSelection = {} } = {} } = useActiveSession();
  return (
    <div className={className}>
      {locked ? (
        <Icon icon="lock" />
      ) : (
        <>
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="flex items-center text-gray-600 hover:text-blue-600 focus:outline-none">
              <span className="sr-only">Open options</span>
              <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black/5 focus:outline-none divide-y divide-gray-100 z-10">
                <div className="py-1">
                  {isFinished(itemStatus) && (
                    <Menu.Item>
                      {({ active }) => (
                        <LinkInPopup
                          className={cn(active ? 'text-gray-900' : 'text-gray-500', 'group flex items-center px-4 py-2 text-sm')}
                          href={`/alterations/new?orderId=${orderId}&fromOrderPage=true`}
                        >
                          <Icon icon="add" className="bg-transparent text-gray-500 mr-3" />
                          Add alteration
                        </LinkInPopup>
                      )}
                    </Menu.Item>
                  )}
                  {!itemStatus && (
                    <>
                      <Menu.Item>
                        {({ active }) => (
                          <LinkInPopup
                            className={cn(active ? 'text-gray-900' : 'text-gray-500', 'group flex items-center px-4 py-2 text-sm')}
                            href={
                              shouldAllowNewDesignFlow && enforcedDesignFlowSelection === 'V2'
                                ? `/orders/${orderId}/order-item/v2/${orderItem.id}/summary`
                                : `/orders/${orderId}/order-item/${orderItem.id}/summary`
                            }
                          >
                            <PencilAltIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            View / Edit
                          </LinkInPopup>
                        )}
                      </Menu.Item>

                      <Menu.Item>
                        {({ active }) => (
                          <button
                            disabled={!canDuplicate}
                            className={cn(
                              'group flex items-center px-4 py-2 text-sm',
                              !canDuplicate
                                ? 'text-gray-300'
                                : {
                                    'text-gray-900': active,
                                    'text-gray-500': !active,
                                  }
                            )}
                            onClick={() => {
                              setDuplicatesList([]);
                              setIsDuplicateModalOpen(true);
                            }}
                          >
                            <DuplicateIcon
                              className={cn('mr-3 h-5 w-5', !canDuplicate ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-500')}
                              aria-hidden="true"
                            />
                            Duplicate
                          </button>
                        )}
                      </Menu.Item>

                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={cn(active ? 'text-red-500' : 'text-gray-500', 'group flex items-center px-4 py-2 text-sm')}
                            onClick={() => setIsDeleteModalOpen(true)}
                          >
                            <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-red-500" aria-hidden="true" />
                            Delete
                          </button>
                        )}
                      </Menu.Item>
                    </>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <RightModal
            isOpen={isDeleteModalOpen}
            setIsOpen={setIsDeleteModalOpen}
            title="Confirm"
            submitButton={
              <Button onClick={onDelete} variant="destructive" isDisabled={isDeleting}>
                Delete
              </Button>
            }
          >
            <p className="mt-6 text-neutral-600">Are you sure you want to delete this item? This cannot be undone.</p>
          </RightModal>
          <RightModal
            isOpen={isDuplicateModalOpen}
            setIsOpen={setIsDuplicateModalOpen}
            title="Select garments to duplicate"
            submitButton={
              <Button onClick={onDuplicate} variant="positive" isDisabled={!duplicatesList.length || isDuplicating}>
                Duplicate
              </Button>
            }
          >
            {!!orderItem?.designs?.length && (
              <ul>
                {orderItem?.designs?.map((d, i, arr) => {
                  const isSelected = duplicatesList.includes(d.id);

                  return (
                    <li
                      key={d.id}
                      onClick={() => updateDuplicatesList(d.id)}
                      className={cn('flex justify-between items-center p-4 hover:bg-gray-50 border-t border-l border-r cursor-pointer', {
                        'border-b': i === arr.length - 1,
                      })}
                    >
                      <span className="whitespace-none">{d.garmentCategory.name}</span>
                      <div
                        className={cn('flex items-center justify-center border rounded-sm w-6 h-6', {
                          'bg-emerald-50 border-emerald-200': isSelected,
                          'bg-white': !isSelected,
                        })}
                      >
                        {isSelected && <CheckIcon className="w-4 h-4 text-emerald-500" />}
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </RightModal>
        </>
      )}
    </div>
  );
};

OrderItemMenuCell.fragments = {
  root: gql`
    fragment OrderItemMenuCell on OrderItem {
      itemStatus {
        key
      }
    }
  `,
};

OrderItemMenuCell.mutation = {
  deleteOrderItem: gql`
    mutation DeleteOrderItem($orderId: ID!, $orderItemId: ID!) {
      deleteOrderItem(orderId: $orderId, orderItemId: $orderItemId)
    }
  `,
  duplicateOrderItem: gql`
    mutation DuplicateOrderItem($orderId: ID!, $orderItemId: ID!, $orderItemDesignIds: [ID!]!) {
      duplicateOrderItem(orderId: $orderId, orderItemId: $orderItemId, orderItemDesignIds: $orderItemDesignIds) {
        id
      }
    }
  `,
};
