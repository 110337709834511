import 'react-toastify/dist/ReactToastify.css';
import 'tailwindcss/tailwind.css';

import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { ReactNode, useEffect } from 'react';

import { useFeature } from 'hooks/useFeature';
import { AppLayout } from 'layouts';
import { ApolloProviderWithAuth0 } from 'providers/apolloAuth0.provider';
import { ddRumService } from 'services/dd-rum.service';
import mixpanelService from 'services/mixpanel.service';
import { IntercomScript } from 'libs/IntercomScript';
import amplitudeService from 'services/amplitude.service';

type NextPageWithLayout = NextPage & {
  getLayout?: (children: ReactNode) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export const baseSiteTitle = 'Taper';

const App = ({ Component, pageProps }): React.ReactElement<AppPropsWithLayout> => {
  const getLayout = Component.getLayout ?? ((page) => <AppLayout>{page}</AppLayout>);
  const { initFeatureService } = useFeature();

  useEffect(() => {
    document.body.classList.add('font-inter');
    document.body.classList.add('text-neutral-900');

    (async () => {
      await initFeatureService();
    })();

    // init DataDog RUM
    ddRumService();

    // init Mixpanel
    mixpanelService.init();
    amplitudeService.init();
  }, []);

  return (
    <>
      <IntercomScript />
      <ApolloProviderWithAuth0>{getLayout(<Component {...pageProps} />)}</ApolloProviderWithAuth0>
    </>
  );
};

export default App;
