import { CANVAS_FABRICS, CANVAS_LINING } from './material-constants';
import { DesignOption, GetImagesResponse, ImageWarning } from '../../types';
import { DEFAULT_DESIGN_OPTIONS, IMG_PREFIX, MAPPED_OPTIONS } from './constants';

const getDesignOptionsComponents = (designOptions: DesignOption[]) => {
  let closure: DesignOption | null = null;
  let lapel: DesignOption | null = null;
  let button: DesignOption | null = null;
  let chestPocket: DesignOption | null = null;
  let sidePocket: DesignOption | null = null;
  let ticketPocket: DesignOption | null = null;
  let sidePocketFlapSize: DesignOption | null = null;
  let formalLapel: DesignOption | null = null;
  let formalLapelColor: DesignOption | null = null;
  let liningPlacement: DesignOption | null = null;

  designOptions.forEach((designOption) => {
    switch (designOption.typeCode) {
      case MAPPED_OPTIONS.closure.typeCode:
        closure = designOption;
        break;
      case MAPPED_OPTIONS.lapel.typeCode:
        lapel = designOption;
        break;
      case MAPPED_OPTIONS.button.typeCode:
        button = designOption;
        break;
      case MAPPED_OPTIONS.chestPocket.typeCode:
        chestPocket = designOption;
        break;
      case MAPPED_OPTIONS.sidePocket.typeCode:
        sidePocket = designOption;
        break;
      case MAPPED_OPTIONS.ticketPocket.typeCode:
        ticketPocket = designOption;
        break;
      case MAPPED_OPTIONS.sidePocketFlapSize.typeCode:
        sidePocketFlapSize = designOption;
        break;
      case MAPPED_OPTIONS.hasFormalLapel.typeCode:
        formalLapel = designOption;
        break;
      case MAPPED_OPTIONS.smokingColor.typeCode:
        formalLapelColor = designOption;
        break;
      case MAPPED_OPTIONS.liningPlacement.typeCode:
        liningPlacement = designOption;
        break;
    }
  });

  return {
    closure,
    lapel,
    button,
    chestPocket,
    sidePocket,
    ticketPocket,
    sidePocketFlapSize,
    formalLapel,
    formalLapelColor,
    liningPlacement,
  };
};

const EXT = {
  button: 'webp',
  fabric: 'webp',
  lining: 'webp',
  smokingColor: 'webp',
};

export const getJacketImages = (url: string, designOptions: DesignOption[], fabricCode?: string, liningCode?: string): GetImagesResponse => {
  const baseUrl = url + '/Jacket_1';
  const images: GetImagesResponse['images'] = [];
  const warnings: ImageWarning[] = [];
  const designOptionComponents = getDesignOptionsComponents(designOptions);

  let mappedClosure = MAPPED_OPTIONS.closure.imageIdPerCode[designOptionComponents.closure?.code];

  if (!mappedClosure) {
    warnings.push({ key: MAPPED_OPTIONS.closure.typeCode, status: 'FALLBACK' });
    mappedClosure = MAPPED_OPTIONS.closure.imageIdPerCode[DEFAULT_DESIGN_OPTIONS.T010202];
  }

  let mappedFabric = CANVAS_FABRICS[fabricCode];
  if (!mappedFabric) {
    mappedFabric = CANVAS_FABRICS[DEFAULT_DESIGN_OPTIONS.FABRIC];
    warnings.push({ key: 'FABRIC', status: 'FALLBACK' });
  }

  let mappedLining = CANVAS_LINING[liningCode];
  if (!mappedLining) {
    mappedLining = CANVAS_LINING[DEFAULT_DESIGN_OPTIONS.LINING];
    warnings.push({ key: 'LINING', status: 'FALLBACK' });
  }

  let liningLayer = '';
  if (MAPPED_OPTIONS.liningPlacement.unlined.includes(designOptionComponents.liningPlacement.code)) {
    liningLayer = `${baseUrl}/Fabric/${mappedFabric}/${IMG_PREFIX}__lining___${mappedFabric}.${EXT.fabric}`;
  } else {
    liningLayer = `${baseUrl}/Lining/${IMG_PREFIX}__lining___${mappedLining}.${EXT.lining}`;
  }
  images.push({ key: 'LINING', url: liningLayer });

  const hemLayer = `${baseUrl}/Fabric/${mappedFabric}/${IMG_PREFIX}__hem___${mappedFabric}.${EXT.fabric}`;
  images.push({ key: 'HEM', url: hemLayer });

  let mappedButton;

  if (MAPPED_OPTIONS.button.codes.includes(designOptionComponents.button.code)) {
    mappedButton = designOptionComponents.button.code;
  } else {
    mappedButton = DEFAULT_DESIGN_OPTIONS.T010207;
    warnings.push({ key: MAPPED_OPTIONS.button.typeCode, status: 'FALLBACK' });
  }

  const noHoleButton = MAPPED_OPTIONS.button.buttonsWithoutHoles.includes(designOptionComponents.button.code);

  let mappedChestPocket = MAPPED_OPTIONS.chestPocket.imageIdPerCode[designOptionComponents.chestPocket.code];

  if (!mappedChestPocket) {
    warnings.push({ key: MAPPED_OPTIONS.chestPocket.typeCode, status: 'FALLBACK' });
    mappedChestPocket = MAPPED_OPTIONS.chestPocket.imageIdPerCode.T01021011;
  }
  if (mappedChestPocket && !mappedChestPocket.original) {
    warnings.push({ key: MAPPED_OPTIONS.chestPocket.typeCode, status: 'FALLBACK' });
  }

  const chestPocketLayer = `${baseUrl}/Fabric/${mappedFabric}/${IMG_PREFIX}__style${mappedClosure}_chestpocket${mappedChestPocket.id}___${mappedFabric}.${EXT.fabric}`;
  images.push({ key: 'chest-pocket', url: chestPocketLayer });

  if (designOptionComponents.chestPocket.code === MAPPED_OPTIONS.chestPocket.withButton) {
    let chestPocketButtonLayer = '';
    if (mappedButton && mappedButton !== MAPPED_OPTIONS.button.fabricButton) {
      chestPocketButtonLayer = `${baseUrl}/button/${mappedButton}/${IMG_PREFIX}__style${mappedClosure}_chestpocket${
        mappedChestPocket.id
      }_chestpocketbutton${noHoleButton ? '2' : '1'}___${mappedButton}.${EXT.button}`;
    } else {
      chestPocketButtonLayer = `${baseUrl}/Fabric/${mappedFabric}/${IMG_PREFIX}__style${mappedClosure}_chestpocket${mappedChestPocket.id}_chestpocketbutton1___${mappedFabric}.${EXT.fabric}`;
    }
    images.push({ key: 'chest-pocket-button', url: chestPocketButtonLayer });

    if (!noHoleButton) {
      let chestPocketButtonThreadLayer = '';
      if (mappedButton && mappedButton !== MAPPED_OPTIONS.button.fabricButton) {
        chestPocketButtonThreadLayer = `${baseUrl}/button/${mappedButton}/${IMG_PREFIX}__style${mappedClosure}_chestpocket${mappedChestPocket.id}_chestpocketbuttonthread___${mappedButton}.${EXT.button}`;
      } else {
        chestPocketButtonThreadLayer = `${baseUrl}/Fabric/${mappedFabric}/${IMG_PREFIX}__style${mappedClosure}_chestpocket${mappedChestPocket.id}_chestpocketbuttonthread___${mappedFabric}.${EXT.fabric}`;
      }
      images.push({ key: 'chestpocketbuttonthread', url: chestPocketButtonThreadLayer });
    }
  }

  const mappedSidePocket = MAPPED_OPTIONS.sidePocket.imageIdPerCode[designOptionComponents.sidePocket.code];

  if (mappedSidePocket) {
    let sidePocketLayer = '';
    const mappedTicketPocket = MAPPED_OPTIONS.ticketPocket.imageIdPerCode[designOptionComponents.ticketPocket.code];

    if (designOptionComponents.ticketPocket.code === MAPPED_OPTIONS.ticketPocket.no) {
      sidePocketLayer = `${baseUrl}/Fabric/${mappedFabric}/${IMG_PREFIX}__style${mappedClosure}_sidepocket${mappedSidePocket}___${mappedFabric}.${EXT.fabric}`;
    } else {
      if (designOptionComponents.ticketPocket.code === MAPPED_OPTIONS.ticketPocket.flapStraight) {
        const mappedTicketPocketSize = MAPPED_OPTIONS.sidePocketFlapSize.imageIdPerCode[designOptionComponents.sidePocketFlapSize.code];

        sidePocketLayer = `${baseUrl}/Fabric/${mappedFabric}/${IMG_PREFIX}__style${mappedClosure}_sidepocket${mappedSidePocket}_ticketpocket3_size${mappedTicketPocketSize}___${mappedFabric}.${EXT.fabric}`;
      } else {
        sidePocketLayer = `${baseUrl}/Fabric/${mappedFabric}/${IMG_PREFIX}__style${mappedClosure}_sidepocket${mappedSidePocket}_ticketpocket${mappedTicketPocket}___${mappedFabric}.${EXT.fabric}`;
      }
    }

    images.push({ key: MAPPED_OPTIONS.sidePocket.typeCode, url: sidePocketLayer });

    // side pocket + button
    if (designOptionComponents.sidePocket.code === MAPPED_OPTIONS.sidePocket.withButton) {
      let sidePocketButton = '';
      if (mappedButton && mappedButton !== MAPPED_OPTIONS.button.fabricButton) {
        sidePocketButton = `${baseUrl}/button/${mappedButton}/${IMG_PREFIX}__style${mappedClosure}_sidepocket${mappedSidePocket}_sidepocketbutton${
          noHoleButton ? '2' : '1'
        }___${mappedButton}.${EXT.button}`;
      } else {
        sidePocketButton = `${baseUrl}/Fabric/${mappedFabric}/${IMG_PREFIX}__style${mappedClosure}_sidepocket${mappedSidePocket}_sidepocketbutton1___${mappedFabric}.${EXT.fabric}`;
      }
      images.push({ key: MAPPED_OPTIONS.sidePocket.typeCode + '_button', url: sidePocketButton });

      if (!noHoleButton) {
        let sidePocketButtonThreadLayer = '';
        if (mappedButton) {
          sidePocketButtonThreadLayer = `${baseUrl}/button/${mappedButton}/${IMG_PREFIX}__style${mappedClosure}_sidepocket${mappedSidePocket}_sidepocketbuttonthread___${mappedButton}.${EXT.button}`;
        } else {
          sidePocketButtonThreadLayer = `${baseUrl}/Fabric/${mappedFabric}/${IMG_PREFIX}__style${mappedClosure}_sidepocket${mappedSidePocket}_sidepocketbuttonthread___${mappedFabric}.${EXT.fabric}`;
        }
        images.push({ key: MAPPED_OPTIONS.sidePocket.typeCode + '_button_thread', url: sidePocketButtonThreadLayer });
      }
    }
  } else {
    warnings.push({ key: MAPPED_OPTIONS.sidePocket.typeCode, status: 'NOT_FOUND' });
  }

  let buttonLayer = '';
  if (mappedButton && mappedButton !== MAPPED_OPTIONS.button.fabricButton) {
    buttonLayer = `${baseUrl}/button/${mappedButton}/${IMG_PREFIX}__style${mappedClosure}_button${noHoleButton ? '2' : '1'}___${mappedButton}.${
      EXT.button
    }`;
  } else {
    buttonLayer = `${baseUrl}/Fabric/${mappedFabric}/${IMG_PREFIX}__style${mappedClosure}_button${noHoleButton ? '2' : '1'}___${mappedFabric}.${
      EXT.fabric
    }`;
  }

  images.push({ key: MAPPED_OPTIONS.button.typeCode, url: buttonLayer });

  if (!noHoleButton) {
    let buttonThreadLayer = '';
    if (mappedButton) {
      buttonThreadLayer = `${baseUrl}/button/${mappedButton}/${IMG_PREFIX}__style${mappedClosure}_buttonthread___${mappedButton}.${EXT.button}`;
    } else {
      buttonThreadLayer = `${baseUrl}/Fabric/${mappedFabric}/${IMG_PREFIX}__style${mappedClosure}_buttonthread___${mappedFabric}.${EXT.fabric}`;
    }
    images.push({ key: MAPPED_OPTIONS.button.typeCode + '_thread', url: buttonThreadLayer });
  }

  const mappedLapel = MAPPED_OPTIONS.lapel.imageIdPerCode[designOptionComponents.lapel.code];

  if (mappedLapel && !mappedLapel.original && !warnings.some((warning) => warning.key === MAPPED_OPTIONS.lapel.typeCode)) {
    warnings.push({ key: MAPPED_OPTIONS.lapel.typeCode, status: 'FALLBACK' });
  }

  let lapelLayer = '';
  // Formal Lapel is 'Yes'
  if (designOptionComponents.formalLapel.code === MAPPED_OPTIONS.hasFormalLapel.yes) {
    const mappedSmokingColor =
      MAPPED_OPTIONS.smokingColor.imageIdPerCode[designOptionComponents.formalLapelColor.code] ??
      MAPPED_OPTIONS.smokingColor.imageIdPerCode[DEFAULT_DESIGN_OPTIONS.T010206];

    if (!mappedSmokingColor.original) {
      warnings.push({ key: MAPPED_OPTIONS.smokingColor.typeCode, status: 'FALLBACK' });
    }

    lapelLayer = `${baseUrl}/Formal Treatment/${mappedSmokingColor.id}/${IMG_PREFIX}__style${mappedClosure}_lapel${mappedLapel.id}___${mappedSmokingColor.id}.${EXT.smokingColor}`;
  } else {
    let mappedLapel = MAPPED_OPTIONS.lapel.imageIdPerCode[designOptionComponents.lapel.code];

    if (mappedLapel === undefined) {
      mappedLapel = MAPPED_OPTIONS.lapel.imageIdPerCode[DEFAULT_DESIGN_OPTIONS.T010203];
      warnings.push({ key: MAPPED_OPTIONS.lapel.typeCode, status: 'FALLBACK' });
    }

    lapelLayer = `${baseUrl}/Fabric/${mappedFabric}/${IMG_PREFIX}__style${mappedClosure}_lapel${mappedLapel.id}___${mappedFabric}.${EXT.fabric}`;
  }
  if (lapelLayer) images.push({ key: 'lapel', url: lapelLayer });

  const sleevesLayer = `${baseUrl}/Fabric/${mappedFabric}/${IMG_PREFIX}__sleeves___${mappedFabric}.${EXT.fabric}`;

  images.push({ key: 'sleeves', url: sleevesLayer });

  return { images, warnings };
};
