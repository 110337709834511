import { gql } from '@apollo/client';
import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import ReactTooltip from 'react-tooltip';

import { OrderPageQuery } from '@graphql';
import { orderService } from 'services/order.service';
import { ArrayElement } from 'types/common';
import { useActiveSession } from 'hooks/useActiveSessionContext';

export const OrderItemDesignCell = ({
  className = '',
  item,
  orderId,
}: {
  className?: string;
  item: ArrayElement<OrderPageQuery['order']['items']>;
  orderId: string;
}) => {
  const { tailor: { offeringConfig: { shouldAllowNewDesignFlow } = {} } = {} } = useActiveSession();
  const { user: { enforcedDesignFlowSelection = {} } = {} } = useActiveSession();
  return (
    <Link
      href={
        shouldAllowNewDesignFlow && enforcedDesignFlowSelection === 'V2'
          ? `/orders/${orderId}/order-item/v2/${item.id}/summary`
          : `/orders/${orderId}/order-item/${item.id}/summary`
      }
      legacyBehavior
    >
      <div className={className}>
        {!orderService.getItemDesignDetails(item).isFinished ? (
          <div
            className="inline-flex items-center bg-red-50 text-red-500 py-1 px-2 rounded text-xs whitespace-nowrap mr-2"
            data-tip={orderService.getItemDesignDetails(item).errorMessage}
          >
            <ExclamationIcon className="h-4 w-4 mr-1" />
            Design
            <ReactTooltip place="top" type="dark" effect="solid" />
          </div>
        ) : (
          <div className="inline-flex items-center bg-emerald-50 text-emerald-500 py-1 px-2 rounded text-xs whitespace-nowrap mr-2">
            <CheckCircleIcon className="h-4 w-4 mr-1" />
            Design
          </div>
        )}
      </div>
    </Link>
  );
};

OrderItemDesignCell.fragments = {
  root: gql`
    fragment OrderItemDesignCell on OrderItem {
      id
      productCategory
      productCategorySetting {
        key
        name
        requiresLining
      }
      fabric {
        id
        status
      }
      customFabric
      lining {
        id
        status
      }
      liningOptions {
        enableCmtLining
        enableCustomLining
        cmtDefaultOptions {
          key
          value
        }
        cmtOptions {
          key
          value
        }
      }
      designs {
        id
        garmentCategory {
          key
          name
        }
        options {
          id
        }
        conflicts {
          message
        }
      }
    }
  `,
};
