import { useState } from 'react';

import { Tabs } from 'components';
import { PricingModal } from 'modules/pricing/components/PricingModal';
import { FabricTab } from 'modules/pricing/components/FabricTab';
import { LiningTab } from 'modules/pricing/components/LiningTab';
import { Price } from 'modules/pricing/pricing.types';
import { IndividualPriceTab } from './IndividualPriceTab';
import { useActiveSession } from 'hooks/useActiveSessionContext';

export const PricingMatrix = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [price, setPrice] = useState(null);
  const { hidePricingTabs } = useActiveSession();
  const onPriceClick = (price: Price) => {
    setIsOpen(true);
    setPrice(price);
  };

  const tabsData = [
    !hidePricingTabs?.includes('Fabric') && {
      name: 'Fabric',
      content: <FabricTab onPriceClick={onPriceClick} />,
    },
    !hidePricingTabs?.includes('Cut Length Fabric') && {
      name: '"Cut Length" Fabric',
      content: <FabricTab onPriceClick={onPriceClick} isCutLength={true} />,
    },
    !hidePricingTabs?.includes('Individual Price Fabric') && {
      name: '"Individual Price" Fabric',
      content: <IndividualPriceTab />,
    },
    !hidePricingTabs?.includes('Lining') && {
      name: 'Lining',
      content: <LiningTab onPriceClick={onPriceClick} />,
    },
  ].filter((x) => !!x);

  return (
    <>
      <Tabs tabs={tabsData} />
      <PricingModal isOpen={isOpen} setIsOpen={setIsOpen} price={price} />
    </>
  );
};
