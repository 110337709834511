import ExternalOpenSvg from 'assets/svg/external-open.svg';

type Props = {
  label: string;
  url: string;
};

export const ExternalLink = ({ label, url }: Props) => {
  return (
    <a href={url} target="_blank" className="h-10 px-4 flex items-center justify-between cursor-pointer" rel="noreferrer">
      <span className="text-sm text-white font-medium rounded">
        {label} <ExternalOpenSvg className="ml-1.5 inline" />
      </span>
    </a>
  );
};
