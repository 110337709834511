import { keyBy } from 'lodash';
import { GetImagesResponse } from './types';

export const imagesAreTheSame = (a: GetImagesResponse['images'], b: GetImagesResponse['images']) => {
  if (a.length !== b.length) return false;

  const aPerKey = keyBy(a, (a) => a.key);
  const bPerKey = keyBy(b, (b) => b.key);

  const keys = new Set([...Object.keys(aPerKey), ...Object.keys(bPerKey)]);
  const allKeys = Array.from(keys);

  for (const key of allKeys) {
    const aImage = aPerKey[key];
    const bImage = bPerKey[key];

    if (aImage?.url !== bImage?.url) {
      return false;
    }
  }

  return true;
};

const warningsAreTheSame = (a: GetImagesResponse['warnings'], b: GetImagesResponse['warnings']) => {
  if (a.length !== b.length) return false;

  const aPerKey = keyBy(a, (a) => a.key);
  const bPerKey = keyBy(b, (b) => b.key);

  const keys = new Set([...Object.keys(aPerKey), ...Object.keys(bPerKey)]);
  const allKeys = Array.from(keys);

  for (const key of allKeys) {
    const aWarning = aPerKey[key];
    const bWarning = bPerKey[key];

    if (aWarning?.status !== bWarning?.status) {
      return false;
    }
  }

  return true;
};

export const areImageResponseEquals = (a: GetImagesResponse | null, b: GetImagesResponse | null) => {
  if ((!a && b) || (a && !b)) {
    return false;
  }

  if (a === null && b === null) {
    return true;
  }

  return imagesAreTheSame(a.images, b.images) && warningsAreTheSame(a.warnings, b.warnings);
};
