import Link from 'next/link';

export const TryItNowLink = () => {
  return (
    <Link className="flex gap-3 items-center text-sm px-4 h-10" href="/design-test">
      <span>Try it now</span>
      <span className="rounded border border-blue-500 bg-blue-50 px-1.5 text-sm text-black">Test Mode</span>
    </Link>
  );
};
