import { OrderPageQuery } from '@graphql';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { RightModal } from 'components/RightModal';
import cn from 'classnames';
import { RemakeFabricDetailSection } from './RemakeFabricDeltaSection';
import { RemakeDesignOptionDeltaSection } from './RemakeDesignOptionDeltaSection';
import { RemakeReasonSection } from './RemakeReasonSection';

type Props = {
  order: OrderPageQuery['order'];
  isOpen: boolean;
  onClose: () => void;
};

export const RemakeDeltaModal = ({ order, isOpen, onClose }: Props) => {
  return (
    <RightModal isOpen={isOpen} onClose={onClose} title={`Remake Order Summary ${order.customId ? ` ${order.customId}` : ''}`}>
      <div className="h-96 overflow-y-auto">
        {order.items.map((item) => (
          <Disclosure key={item.id} as="div" className="even:border-y first:border-t last:border-b border-x">
            {({ open }) => (
              <>
                <div className="flex items-center">
                  <Disclosure.Button className="grow py-3.5 px-4 flex justify-between items-center">
                    <span>{`${item.number} - ${item.name}`}</span>
                    <ChevronUpIcon
                      className={cn('h-5 w-5 text-gray-500', {
                        'rotate-180 transform': !open,
                      })}
                    />
                  </Disclosure.Button>
                </div>
                <Disclosure.Panel className="border-t">
                  <RemakeReasonSection reason={item.remake.remakeReason} description={item.remake.remakeReasonText} />
                  <RemakeFabricDetailSection fabricsDeltas={item.remake.delta.fabricsDelta} />
                  <RemakeDesignOptionDeltaSection designOptionDeltas={item.remake.delta.designOptionDeltas} designs={item.designs} />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </RightModal>
  );
};
