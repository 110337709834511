import { DesignOptionsFormProviderOrderItemQueryResult, LiningOptions } from '@graphql';
import { keyBy } from 'lodash';
import { DesignOptionKeyValue } from 'modules/designOptions';

export const JEROME_CMT_LINING_VENDOR_KEY = 'li-vnd';
export const JEROME_CMT_LINING_QUANTITY = 'li-qty';
export const JEROME_CMT_LINING_CODE = 'li-code';
export const JEROME_CMT_LINING_COMPOSITION = 'li-comp';
export const JEROME_CMT_LINING_VENDOR_CUSTOM_LINING_OPTION = 'Custom Lining';

export const getIsFabricOrLiningInStock = (fabricStatus: string) => {
  // An empty fabric status is treated the same as an in-stock fabric (we don't have information on whether it
  // is in stock or not, so the user can choose to use if they wish)
  return ['available', 'low stock', ''].includes(fabricStatus?.toLowerCase());
};

/**
 * When the tailor select a cmt lining, there is one special option called "Custom lining" this option required the cmt composition to be set
 */
export const isCustomLining = (cmtOptionValue: string) => cmtOptionValue.toLowerCase().includes('custom lining');

const hasCustomLining = (liningOptions: LiningOptions['cmtOptions']) => {
  const vendorOption = liningOptions.find((option) => option.key === 'li-vnd');
  return vendorOption ? isCustomLining(vendorOption.value) : false;
};

export const areSelectedCmtLiningValid = (liningOptions: LiningOptions) => {
  let liningOptionsToCheck = liningOptions.cmtOptions;
  if (!hasCustomLining(liningOptions.cmtOptions)) {
    liningOptionsToCheck = liningOptionsToCheck.filter((opt) => opt.key !== JEROME_CMT_LINING_COMPOSITION);
  }
  return liningOptionsToCheck.some((opt) => {
    const defaultOption = liningOptions.cmtDefaultOptions.find(({ key }) => key === opt.key);
    return defaultOption.value !== opt.value;
  });
};

export const isCmtLiningOptionSelected = (liningOptions: LiningOptions) => {
  if (!liningOptions.enableCmtLining && !liningOptions.enableCustomLining) {
    return false;
  }

  if (liningOptions.cmtDefaultOptions.length === 0) {
    return false;
  }

  const vendorCmtOption = liningOptions.cmtOptions.find(({ key }) => key === JEROME_CMT_LINING_VENDOR_KEY);
  const vendorCmtDefaultOption = liningOptions.cmtDefaultOptions.find(({ key }) => key === JEROME_CMT_LINING_VENDOR_KEY);

  if (!vendorCmtOption) {
    return false;
  }

  return vendorCmtOption?.value !== vendorCmtDefaultOption?.value;
};

export const updateDesignsWithCmtLiningOptions = (
  newCmtOptions: LiningOptions['cmtOptions'],
  designs: DesignOptionsFormProviderOrderItemQueryResult['data']['orderItem']['designs']
) =>
  designs.map((design) => ({
    ...design,
    options: design.options.map((opt) => {
      const liningOptionToUpdate = newCmtOptions.find((o) => o.key === opt.typeCode);
      if (liningOptionToUpdate) {
        return { ...opt, value: liningOptionToUpdate.value };
      }
      return opt;
    }),
  }));

export const getNewCmtOptions = (liningOptions: LiningOptions, newOptions: DesignOptionKeyValue[]): LiningOptions['cmtOptions'] => {
  const vendorOption = newOptions.find((opt) => opt.typeCode === JEROME_CMT_LINING_VENDOR_KEY);
  const defaultVendorOption = liningOptions.cmtDefaultOptions.find((opt) => opt.key === JEROME_CMT_LINING_VENDOR_KEY);

  if (vendorOption && vendorOption.value === defaultVendorOption.value) {
    return liningOptions.cmtDefaultOptions;
  }
  const newLiningOptions = liningOptions.cmtOptions.map((opt) => {
    const newOption = newOptions.find((o) => o.typeCode === opt.key);
    return {
      key: opt.key,
      value: newOption ? newOption.value : opt.value,
      __typename: 'CmtOption' as const,
    };
  });
  if (vendorOption && !isCustomLining(vendorOption.value)) {
    const compositionOption = newLiningOptions.find(({ key }) => key === JEROME_CMT_LINING_COMPOSITION);
    const defaultCompositionOption = liningOptions.cmtOptions.find((opt) => opt.key === JEROME_CMT_LINING_COMPOSITION);
    compositionOption.value === defaultCompositionOption.value;
  }
  return newLiningOptions;
};

export const getCmtLiningComponents = (liningOptions: LiningOptions) => {
  const cmtOptionsPerKey = keyBy(liningOptions.cmtOptions, 'key');

  return {
    vendor: cmtOptionsPerKey[JEROME_CMT_LINING_VENDOR_KEY]?.value,
    composition: cmtOptionsPerKey[JEROME_CMT_LINING_COMPOSITION]?.value,
    quantity: cmtOptionsPerKey[JEROME_CMT_LINING_QUANTITY]?.value,
    code: cmtOptionsPerKey[JEROME_CMT_LINING_CODE]?.value,
  };
};

export const extractDesignOptions = (orderItem: DesignOptionsFormProviderOrderItemQueryResult['data']['orderItem']) => {
  const supportedDesign = orderItem.designs.find((d) => d.options.some((o) => o.typeCode === JEROME_CMT_LINING_VENDOR_KEY));
  const optionsPerKey = supportedDesign ? keyBy(supportedDesign.options, 'typeCode') : {};

  return [
    optionsPerKey[JEROME_CMT_LINING_VENDOR_KEY],
    optionsPerKey[JEROME_CMT_LINING_QUANTITY],
    optionsPerKey[JEROME_CMT_LINING_CODE],
    optionsPerKey[JEROME_CMT_LINING_COMPOSITION],
  ].filter((o) => !!o);
};
